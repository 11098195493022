<div
  class="__table-wrap"
  [class.is-loading]="isLoading">
  <vshc-item-list-table
    [disableHover]="disableTableHover"
    [count]="items?.length"
    [paginatorPageSize]="paginatorPageSize"
    [showAll]="showAll$ | async"
    [items]="items"
    [mode]="mode"
    [columns]="columns"
    [colsTranslations]="colsTranslations"
    [trackBy]="trackBy"
    [currentPage]="currentPage"
    [paginatorLength]="paginatorLength"
    (page)="page.emit($event)"
    (sorted)="sorted.emit($event)"
    [customClassProperty]="customClassProperty"
    [columnTemplateMap]="colTemplatesMap"
    [actionsTemplates]="actionTemplates"
    [itemStateTemplate]="itemStateTemplateRef"
    [linkPath]="tableLink"
    [defaultSort]="defaultSort"
    (clicked)="tableRowClicked.emit($event)"
    [defaultSortDirection]="defaultSortDirection"
    (showChanged)="showAll$.next($event)">
  </vshc-item-list-table>
</div>

// Decoding base64 ⇢ UTF8
function b64DecodeUnicode(str: string) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c: string) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

export function parseAuthToken(token: string) {
  const tokenData = b64DecodeUnicode(decodeURIComponent(token));
  const [ accessToken, refreshToken, clientServiceId, clientUserId ] = tokenData.split('|');

  return {
    accessToken,
    refreshToken,
    clientServiceId,
    clientUserId
  };
}

import { createActionGroup } from '@ngrx/store';
import { zefActionFailPayload, zefActionPayload, zefActionSuccessPayload } from '@zerops/zef';
import { AliasAddRecordResponse } from './alias-base.model';

export const AliasBaseActions = createActionGroup({
  source: '@vshapps/cdn/core/alias-base',
  events: {
    'alias add record': zefActionPayload<string>(),
    'alias add record success': zefActionSuccessPayload<AliasAddRecordResponse>(),
    'alias add record fail': zefActionFailPayload,
  }
});

<div class="__warning-wrap" *ngIf="showWarning && state.hasAnyInactiveAlias">

  <mat-icon class="__warning-bg-icon">error_outline</mat-icon>

  <h4 class="__warning-wrap_title">
    <span>{{ state.translations?.someNonVerifiedWarningDesc }}</span>
  </h4>

  <div class="__warning-grid">
    <div>
      <h5 class="__warning-value-title">{{ state.translations?.cnameVerified }}</h5>
    </div>
    <div>
      <span class="__warning-value-value">{{ cname }}</span>
    </div>
  </div>

  <div class="__dns-manual-check-wrap">

    <p class="__dns-manual-check-desc">{{ state.translations.dnsManualCheckDesc }}</p>

    <zef-progress-error [key]="aliasDnsCheckKey">
      <button
        type="button"
        class="__dns-manual-check-button  c-button--smaller"
        (click)="onAliasDnsCheck$.next(id)"
        mat-flat-button>
        <span class="u-valign--middle">{{ state.translations.dnsManualCheck }}</span>
        <mat-icon>published_with_changes</mat-icon>
      </button>
    </zef-progress-error>

  </div>

</div>

<div class="__items-wrap" *ngIf="state.aliases?.length">
  <div
    *ngFor="let item of state.aliases; let last = last; trackBy: trackByIndex;"
    [class.is-active]="item?.certificate?.status === 'ACTIVE'"
    class="__item">

    <h4 class="__alias-name">

      <div>
        <vshc-status-icon
          [size]="14"
          class="u-valign--middle  __alias-status-icon"
          [color]="aliasStatusColorMap[(item.certificate?.status === 'ACTIVE') + '']">
        </vshc-status-icon><span class="__alias-name-text">{{ item.aliasDomain }}</span>
      </div>

      <div>
        <span class="__badge" [class.is-active]="!!item.cnameVerified">{{ state.translations?.cnameVerified }}</span>
      </div>

      <div *ngIf="!!item.cnameVerified">
        <span
          [matTooltip]="state.translations.statuses[item.certificate?.status || 'WAITING']"
          class="__badge"
          [class.is-active]="item.certificate?.status === 'ACTIVE'">{{ state.translations?.ssl }}&nbsp;<mat-icon>help_outline</mat-icon></span>
      </div>

    </h4>

    <div
      class="__cloud-dns-fix-wrap"
      *ngIf="!!showAdd
        && !!item.isCloudDnsZone
        && !item.cnameCleanup">
      <button
        mat-flat-button
        color="accent"
        type="button"
        class="c-button--smaller"
        (click)="onAliasAddRecord$.next(item.id)">
        <mat-icon fontSet="material-icons-outlined" class="u-valign--middle">auto_fix_normal</mat-icon>&nbsp;<span>{{ state.translations?.aliasAddRecord }}</span>
      </button>
    </div>

    <button
      class="__remove-button"
      [class.is-force-shown]="forceDeleteShow"
      mat-icon-button
      color="warn"
      type="button"
      (click)="removingId = item.id; deleteButtonRef.open()"
      [vshcDynamicPopAnchor]="confirmPopRef.popRef"
      #deleteButtonRef="dynamicPopAnchor">
      <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
    </button>

  </div>
</div>

<div class="__add-button-wrap" *ngIf="showAdd">
  <button
    mat-stroked-button
    color="primary"
    type="button"
    class="c-button--smaller  c-button--full  __add-button"
    [zefNgrxDialogOpen]="addAliasDialogKey">
    <mat-icon class="u-valign--middle">add</mat-icon>&nbsp;<span>{{ state.translations?.addNewAlias }}</span>
  </button>
</div>

<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title"
  (closeTrigger)="onClose$.next()">
  <div class="__form-wrap">
    <vshcdn-add-alias-form
      #addAliasFormRef
      class="__form"
      (submit)="onAddAlias$.next()"
      [state]="state.addAliasFormState"
      [translations]="state.translations">
      <zef-progress-error full [key]="addAliasActionKey">
        <button
          class="c-button--larger  c-button--full"
          mat-flat-button
          type="button"
          (click)="addAliasFormRef.triggerSubmit()"
          color="primary">
          {{ state.translations?.addAlias }}
        </button>
      </zef-progress-error>
    </vshcdn-add-alias-form>
  </div>
</vshc-dialog-wrapper>

<!-- delete confirm pop -->
<vshc-confirm-pop
  #confirmPopRef
  [heading]="state.translations?.aliasDeleteConfirmPopTitle"
  [confirmButtonText]="state.generalTranslations?.confirm"
  [verticalAlign]="'below'"
  [horizontalAlign]="'center'"
  [confirmColor]="'warn'"
  [closeButtonText]="state.generalTranslations?.close"
  (confirm)="onRemoveAlias$.next(removingId)">
</vshc-confirm-pop>
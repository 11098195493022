<mat-card class="__card  c-soft-elevation">
  <div class="__grid">

    @if (data.translation) {
      <div [transloco]="data.translation"></div>
    }

    @if (data.text) {
      <div>{{ data.text }}</div>
    }

    <div class="u-text--right">

      @if (!data.actionButtonTranslation && !data.actionButtonText) {
        <button
          class="__button"
          mat-button
          type="button"
          (click)="instance.closeWithAction()"
          transloco="general.close">
        </button>
      }

      @if (!!data.actionButtonTranslation) {
        <button
          class="__button"
          mat-button
          type="button"
          (click)="instance.closeWithAction()"
          [transloco]="data.actionButtonTranslation">
        </button>
      }

      @if (!!data.actionButtonText) {
        <button
          class="__button"
          mat-button
          type="button"
          (click)="instance.closeWithAction()">
          {{ data.actionButtonText }}
        </button>
      }

    </div>

  </div>
</mat-card>

import { Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

import { ZefError, SatPopover } from '@zerops/zef';

@Component({
  standalone: true,
  selector: 'vshcdn-pop-error',
  templateUrl: './pop-error.feature.html',
  styleUrls: [ './pop-error.feature.scss' ],
  imports: [
    NgFor,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatCardModule
  ]
})
export class PopErrorFeature {
  @Input()
  data!: ZefError[];

  @Input()
  instance!: SatPopover;

  trackBy(index: number) {
    return index;
  }
}

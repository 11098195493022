import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_NAME } from './domain-base.constant';
import { DomainBaseState } from './domain-base.model';

export const selectDomainBaseState = createFeatureSelector<DomainBaseState>(FEATURE_NAME);

export const selectDomainBaseAliases = createSelector(
  selectDomainBaseState,
  (state) => state.aliases
);

export const selectDomainBaseStatistics = createSelector(
  selectDomainBaseState,
  (state) => state.statistics
);

export const selectDomainBaseAliasesForDomainId = (id: string) => createSelector(
  selectDomainBaseAliases,
  (aliases) => aliases?.[id]
);

export const selectDomainBaseStatisticsForId = (id: string) => createSelector(
  selectDomainBaseStatistics,
  (statistics) => statistics?.[id]
);

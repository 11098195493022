import { Injectable } from '@angular/core';
import { validateUrlsCreator } from '@vshosting/components';
import { environment } from '@vshosting/cdn/env';
import { ZefForm } from '@zerops/zef';
import toNumber from 'lodash-es/toNumber';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './purge-cache-dialog.constant';
import { PurgeCacheFormValue } from './components/purge-cache-form';

@Injectable({ providedIn: 'root' })
export class PurgeCacheDialogForm extends ZefForm<PurgeCacheFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        urls: undefined
      },
      {
        urls: validate(
          required,
          validateUrlsCreator(toNumber(environment.maxPurgeUrls))
        )
      }
    );
  }
}

import { inject, Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map
} from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import { onSetId } from '@zerops/zef';
import { DEFAULT_DOMAIN_STATISTICS_RANGE, RouteKeys } from '@vshosting/cdn/app';
import { DomainBaseActions } from '@vshosting/cdn/core';

@Injectable()
export class DomainDetailEffect {

  // # Deps
  #actions$ = inject(Actions);

  // # Streams
  #onSetId$ = this.#actions$.pipe(
    onSetId(RouteKeys.DomainDetail),
    filter((d) => !!d),
    distinctUntilChanged()
  );

  // # Effects
  // -- actions
  onSetIdLoadDomainStatistics$ = createEffect(() => this.#onSetId$.pipe(
    map((id) => DomainBaseActions.loadStatistics({
      id,
      filter: DEFAULT_DOMAIN_STATISTICS_RANGE
    }))
  ));

}

import { inject, Injectable } from '@angular/core';
import {
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';

import {
  zefDialogClose,
  zefDialogOpen,
  ZefSnackService
} from '@zerops/zef';
import { DomainBaseActions } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './warm-cache-dialog.constant';
import { WarmCacheDialogForm } from './warm-cache-dialog.form';

@Injectable()
export class WarmCacheDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #warmCacheDialogForm = inject(WarmCacheDialogForm);
  #snackService = inject(ZefSnackService);
  #translocoService = inject(TranslocoService);

  // # Data
  // -- async
  translations$ = this.#translocoService.selectTranslateObject('warmCacheDialog');

  // # Streams
  #warmCacheSuccess$ = this.#actions$.pipe(
    ofType(DomainBaseActions.warmCacheSuccess),
    filter((d) => d.originalAction.meta?.tag === FEATURE_NAME)
  );

  #onWarmCacheDialogOpen$ = this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogOpen.type && key === FEATURE_NAME)
  );

  // # Effects
  onWarmCacheSuccessCloseDialog$ = createEffect(() => this.#warmCacheSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onWarmCacheDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogClose.type && key === FEATURE_NAME),
    delay(150),
    mergeMap(() => ([
      this.#warmCacheDialogForm.reset(),
      this.#warmCacheDialogForm.setDefaultValues()
    ]))
  ));

  onPurgeCacheDialogOpenFocusDomainName$ = createEffect(() => this.#onWarmCacheDialogOpen$.pipe(
    delay(150),
    map(() => this.#warmCacheDialogForm.focus('urls'))
  ));

  onWarmCacheSuccessNotification$ = createEffect(() => this.#warmCacheSuccess$.pipe(
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.warmCacheSuccess }))
  ), { dispatch: false });

}

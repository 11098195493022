import { inject, Injectable } from '@angular/core';
import { filter, map } from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import { successOf, zefDialogClose, zefGo } from '@zerops/zef';
import { DomainEntity } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './delete-domain-dialog.constant';

@Injectable()
export class DeleteDomainDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #domainEntity = inject(DomainEntity);

  // # Streams
  #onDomainDeleteDialogSuccess$ = this.#actions$.pipe(
    successOf(this.#domainEntity.deleteOne),
    filter(({ originalAction }) => originalAction.meta?.tag === FEATURE_NAME)
  );

  // # Effects
  onDomainDeleteSuccessCloseDialog$ = createEffect(() => this.#onDomainDeleteDialogSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDomainDeleteSuccessRedirect$ = createEffect(() => this.#onDomainDeleteDialogSuccess$.pipe(
    map(() => zefGo([ '/' ]))
  ));
}

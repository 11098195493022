import { inject, Injectable } from '@angular/core';
import {
  catchError,
  filter,
  map,
  of,
  retry,
  switchMap,
  take
} from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import { successOf, zefDialogClose, zefDialogOpen } from '@zerops/zef';
import { CdnDomain } from '@vshosting/models';
import {
  ClientServiceBaseApi,
  DomainEntity
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './domain-existence-check-dialog.constant';

@Injectable()
export class DomainExistenceCheckDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #domainEntity = inject(DomainEntity);
  #clientServiceBaseApi = inject(ClientServiceBaseApi);

  // # Streams
  #domainUpdateSuccess$ = this.#actions$.pipe(
    successOf(this.#domainEntity.updateOne),
    filter(({ originalAction }) => originalAction?.meta?.tag === FEATURE_NAME)
  );

  // # Effects
  onDomainAddCheckAndOpen$ = createEffect(() => this.#actions$.pipe(
    successOf<CdnDomain>(this.#domainEntity.addOne),
    switchMap(({ meta }) => this.#domainEntity
      .entityById$(meta.rawResult?.id)
      .pipe(
        filter((d) => !!d),
        take(1),
        switchMap((d) => this.#clientServiceBaseApi.recentDomainExistenceCheck$(
          d.clientServiceId,
          d.domainName
        ).pipe(
          retry(1),
          map((data) => zefDialogOpen({ key: FEATURE_NAME, meta: { data, id: meta.rawResult.id } })),
          catchError(() => of(undefined))
        ))
      )
    ),
    filter((d) => !!d)
  ));

  onDomainUpdateSuccessCloseDialog$ = createEffect(() => this.#domainUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));
}

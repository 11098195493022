import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ZefFormBase, ZefFormsModule } from '@zerops/zef';
import { PurgeCacheFormState } from './purge-cache-form.model';

@Component({
  standalone: true,
  selector: 'vshcdn-purge-cache-form',
  templateUrl: './purge-cache-form.component.html',
  styleUrls: [ './purge-cache-form.component.scss' ],
  imports: [
    NgIf,
    MatInputModule,
    MatFormFieldModule,
    ZefFormsModule
  ]
})
export class PurgeCacheFormComponent extends ZefFormBase<PurgeCacheFormState> {

  @Input()
  translations: any;

  @Input()
  formErrorTranslations: any;

}

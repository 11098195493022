import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_NAME } from './client-service-base.constant';
import { ClientServiceBaseState } from './client-service-base.model';

export const selectClientServiceBaseState = createFeatureSelector<ClientServiceBaseState>(FEATURE_NAME);

export const selectClientServiceBaseData = createSelector(
  selectClientServiceBaseState,
  (state) => state.data
);

export const selectClientServiceBaseDataAllowedLocations = createSelector(
  selectClientServiceBaseData,
  (data) => data?.allowedLocations
);

export const selectClientServiceStatistics = createSelector(
  selectClientServiceBaseState,
  (state) => state.statistics
);

export const selectClientServiceAggregatedStatistics = createSelector(
  selectClientServiceBaseState,
  (state) => state.aggregatedStatistics
);

<zef-form
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field class="__form-field">
    <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

      <mat-label>{{ translations?.addDomainForm.yourDomainLabel }}</mat-label>

      <input
        matInput
        type="text"
        [placeholder]="defaultExampleDomain"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.backendDomain"
      />

      <mat-hint>{{ translations?.addDomainForm.yourDomainHint }}</mat-hint>

    </mat-form-field>
  </zef-form-field>

  <zef-form-field class="__form-field">
    <mat-form-field color="accent">

      <mat-label>{{ translations?.addDomainForm.cdnDomainLabel }}</mat-label>

      <input
        matInput
        type="text"
        [placeholder]="state?.controls.backendDomain.value | sanitizeDomain"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.cdnDomain"
      />

      <div class="__text-suffix" matSuffix>.{{ cdnHost }}</div>

      <mat-hint>{{ translations?.addDomainForm.cdnDomainHint }}</mat-hint>

    </mat-form-field>
  </zef-form-field>

  <zef-form-field class="__form-field">
    <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

      <mat-label>{{ translations?.addDomainForm.locationLabel }}</mat-label>

      <mat-select
        multiple
        [placeholder]="translations?.addDomainForm.locationPlaceholder"
        [ngrxFormControlState]="state?.controls.locations"
        [ngrxEnableFocusTracking]="true">

        <mat-option *ngFor="let item of locations" [value]="item.id">{{ item.name }}</mat-option>

      </mat-select>

    </mat-form-field>
  </zef-form-field>

  <zef-form-field appearance="slide-toggle">

    <mat-slide-toggle
      [ngrxFormControlState]="state?.controls.disabled"
      [ngrxEnableFocusTracking]="true">
      {{ translations?.addDomainForm.disabledDomainToggle }}
    </mat-slide-toggle>

    <zef-form-hints>{{ translations?.addDomainForm.disabledDomainToggleHint }}</zef-form-hints>

  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
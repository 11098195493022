import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ZefDialogModule, ZefScrollModule } from '@zerops/zef';

@Component({
  selector: 'vshc-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ZefDialogModule,
    ZefScrollModule
  ]
})
export class VshcDialogWrapperComponent {

  @Input()
  open: boolean;

  @Input()
  heading: string;

  @Input()
  description: string;

  @Output()
  closeTrigger = new EventEmitter();

}

<mat-card class="__card  c-soft-elevation">
  <div class="__grid">

    <div *ngIf="data.translation" [transloco]="data.translation"></div>

    <div *ngIf="data.text">{{ data.text }}</div>

    <div class="u-text--right">

      <button
        *ngIf="!data.actionButtonTranslation && !data.actionButtonText"
        class="__button"
        mat-button
        type="button"
        (click)="instance.closeWithAction()"
        transloco="general.close">
      </button>

      <button
        *ngIf="!!data.actionButtonTranslation"
        class="__button"
        mat-button
        type="button"
        (click)="instance.closeWithAction()"
        [transloco]="data.actionButtonTranslation">
      </button>

      <button
        *ngIf="!!data.actionButtonText"
        class="__button"
        mat-button
        type="button"
        (click)="instance.closeWithAction()">
        {{ data.actionButtonText }}
      </button>

    </div>

  </div>
</mat-card>
import { Injectable } from '@angular/core';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import toNumber from 'lodash-es/toNumber';

import { ZefForm } from '@zerops/zef';
import { validateUrlsCreator } from '@vshosting/components';
import { environment } from '@vshosting/cdn/env';
import { WarmCacheFormValue } from './components';
import { FEATURE_NAME } from './warm-cache-dialog.constant';

@Injectable({ providedIn: 'root' })
export class WarmCacheDialogForm extends ZefForm<WarmCacheFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        urls: undefined
      },
      {
        urls: validate(
          required,
          validateUrlsCreator(toNumber(environment.maxWarmCacheUrls))
        )
      }
    );
  }
}

import { createActionGroup, createFeature, createReducer, on } from '@ngrx/store';

import { zefResetState } from '@zerops/zef';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef';
import {
  Country,
  GeoIpMode,
  RemoteLogType,
  Location
} from '@vshosting/models'
import { FEATURE_NAME } from './settings-base.constant';
import { SettingsBaseState } from './settings-base.model';

export const SettingsBaseActions = createActionGroup({
  source: FEATURE_NAME,
  events: {
    'load settings': zefActionPayload<void>(),
    'load settings fail': zefActionFailPayload,
    'load settings success': zefActionSuccessPayload<{
      geoIpMode: GeoIpMode[];
      remoteLogType: RemoteLogType[];
      locations: Location[];
      countries: Country[];
    }>()
  }
});

const initialState = new SettingsBaseState();

export const settingsBaseState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(
      SettingsBaseActions.loadSettingsSuccess,
      (s, { data }): SettingsBaseState => ({
          ...s,
          ...data
        })
    ),
    on(zefResetState, () => initialState)
  )
});

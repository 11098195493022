import { NgModule } from '@angular/core';

import {
  HttpStatusCodes,
  META_CONFIG,
  ZefMetaModule,
  ZefNgrxRouterModule,
  ZefEntitiesModule,
  CollectionManagerService,
  ZefAuthModule,
  ZefAuthRefreshTokenModule,
  ZefWebsocketModule,
  ZefErrorsModule,
  ZefProgressModule,
  ZefDialogModule,
  ZefFormsModule,
  ZefPopErrorModule,
  ZefSnackErrorModule,
  ZefRecaptchaModule
} from '@zerops/zef';
import { environment } from '@vshosting/cdn/env';
import { ApiEntityKeys } from '@vshosting/cdn/app';

@NgModule({
  imports: [
    ZefEntitiesModule.forRoot({
      config: {
        apiPrefix: '/api'
      }
    }),
    ZefRecaptchaModule.forRoot(),
    // !!! keep order of AuthRefresh, Auth and Error imports
    // needed for the correct interceptor chain
    ZefAuthRefreshTokenModule,
    ZefErrorsModule.forRoot({
      // we don't want to show 401 error, as it always
      // triggers refresh, which either successes and
      // "nothing" visible happens for the user
      blacklistedErrors: [ HttpStatusCodes.UNAUTHORIZED ]
    }),
    ZefFormsModule.forRoot(),
    ZefProgressModule.forRoot(),
    ZefAuthModule.forRoot({
      loginEndpoint: `${environment.authApiUrl}/login`,
      logoutEndpoint: `${environment.authApiUrl}/logout`,
      refreshEndpoint: `${environment.authApiUrl}/refresh`
    }),
    ZefNgrxRouterModule.forRoot(),
    ZefWebsocketModule.forRoot({
      loginUrl: environment.websocketAuthUrl,
      apiUrl: environment.websocketApiPath,
      host: environment.websocketHost,
      forceSecuredEndpoint: true,
      pingPongEnabled: true
    }),
    ZefDialogModule.forRoot(),
    ZefPopErrorModule,
    ZefSnackErrorModule,
    ZefMetaModule.forRoot(),
  ],
  providers: [
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: 'vshosting CDN',
        separator: '~'
      }
    },
  ]
})
export class ZefLibModule {
  constructor(private _collectionManagerService: CollectionManagerService) {
    this._collectionManagerService.create({
      [ApiEntityKeys.User]: {
        definitions: {
          clientUserList: [ ApiEntityKeys.ClientUser ]
        }
      },
      [ApiEntityKeys.Domain]: {},
      [ApiEntityKeys.DataDomain]: {},
      [ApiEntityKeys.ClientUser]: {},
      [ApiEntityKeys.Alias]: {},
      [ApiEntityKeys.NodeTaskQueueConsolidated]: {}
    });
  }
}

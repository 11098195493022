import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

import {
  SatPopoverModule,
  SatPopover,
  SatPopoverHorizontalAlign,
  SatPopoverVerticalAlign
} from '@zerops/zef';

@Component({
  standalone: true,
  selector: 'vshc-confirm-pop',
  templateUrl: './confirm-pop.component.html',
  styleUrls: [ './confirm-pop.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    SatPopoverModule
  ]
})
export class ConfirmPopComponent {

  @Input()
  confirmButtonText: string;

  @Input()
  confirmButtonIcon: string;

  @Input()
  confirmColor: string;

  @Input()
  closeButtonText: string;

  @Input()
  heading: string;

  @Input()
  verticalAlign: SatPopoverVerticalAlign;

  @Input()
  horizontalAlign: SatPopoverHorizontalAlign;

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  closeTrigger = new EventEmitter<void>();

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;
}

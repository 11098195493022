import { Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import {
  map,
  Subject,
  withLatestFrom
} from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { select, Store } from '@ngrx/store';
import { unbox } from 'ngrx-forms';
import { TranslocoService } from '@ngneat/transloco';

import {
  ZefReactiveComponentBase,
  formValueOnValid,
  selectZefDialogState,
  zefDialogClose
} from '@zerops/zef';
import { ZefProgressErrorModule } from '@zerops/zef';
import { locationSeriesReducer, VshcDialogWrapperComponent } from '@vshosting/components';
import {
  UserEntity,
  DomainEntity,
  selectClientServiceBaseDataAllowedLocations
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './add-domain-dialog.constant';
import { AddDomainDialogForm } from './add-domain-dialog.form';
import { AddDomainFormComponent } from './components/add-domain-form';
import { sanitizeStandardizeDomain } from './add-domain-dialog.utils';

@Component({
  standalone: true,
  selector: 'vshcdn-add-domain-dialog',
  templateUrl: './add-domain-dialog.feature.html',
  styleUrls: [ './add-domain-dialog.feature.scss' ],
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    ZefProgressErrorModule,
    AddDomainFormComponent,
    VshcDialogWrapperComponent
  ]
})
export class AddDomainDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #domainEntity = inject(DomainEntity);
  #userEntity = inject(UserEntity);
  #store = inject(Store);
  #translocoService = inject(TranslocoService);

  // # Forms
  addDomainForm = inject(AddDomainDialogForm);

  // # Event streams
  onAdd$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  addActionKey = this.#domainEntity.addOne.type;
  open$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d?.state)
  );
  allowedLocations$ = this.#store.pipe(
    select(selectClientServiceBaseDataAllowedLocations)
  );
  translations$ = this.#translocoService.selectTranslateObject('addDomainDialog');

  // # Resolver
  state = this.$connect({
    addDomainFormState: this.addDomainForm.state$,
    open: this.open$,
    allowedLocations: this.allowedLocations$,
    translations: this.translations$
  });

  // # Action streams
  #addAction$ = this.onAdd$.pipe(
    formValueOnValid(this.addDomainForm),
    withLatestFrom(this.#userEntity.activeClientServiceId$),
    map(([ data, clientServiceId ]) => this.#domainEntity.addOne(
      {
        ...data,
        // use sanitized domainName if cdnDomain is not defined
        cdnDomain: data.cdnDomain
          ? data.cdnDomain
          : sanitizeStandardizeDomain(data.backendDomain),
        clientServiceId,
        location: unbox(data.locations)?.reduce(locationSeriesReducer, 0)
      },
      { tag: FEATURE_NAME }
    ))
  );

  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this.#addAction$,
      this.#closeAction$
    ]);
  }

}

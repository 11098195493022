<zef-dialog [open]="state.isOpen">
  <div class="__wrap">
    <div class="__content">
      <h2
        class="__heading"
        [innerHTML]="state.tranlations?.title">
      </h2>

      <a
        [href]="authWebsiteUrl"
        mat-flat-button
        class="__button  c-button--larger"
        color="accent">
        <mat-icon class="__icon">login</mat-icon>
        &nbsp;
        <span>{{ state.tranlations?.authWebsiteButton }}</span>
      </a>
    </div>
  </div>

  <!-- Disabled until direct login to CDN is enabled -->
  <!--
  <div class="__form-wrap">
    <vshcdn-login-form [state]="state.loginFormState">
      <zef-progress-error [key]="loginKey">
        <button
          mat-flat-button
          color="primary"
          (click)="onSubmit$.next()"
          type="button">
          Login
        </button>
      </zef-progress-error>
    </vshcdn-login-form>
  </div>
  -->

</zef-dialog>
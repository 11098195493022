import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import omitBy from 'lodash-es/omitBy';

import { CdnClientService, CdnDomain } from '@vshosting/models';
import { TerminateTrialResponse } from './client-service-base.model';

@Injectable({ providedIn: 'root' })
export class ClientServiceBaseApi {
  #http = inject(HttpClient);

  loadClientService$(clientId: string) {
    return this.#http.get<CdnClientService>(`/api/client/${clientId}/client-service`);
  }

  terminateTrial$(clientServiceId: string) {
    return this.#http.put<TerminateTrialResponse>(`/api/client-service/${clientServiceId}/trial-termination`, {});
  }

  loadStatistics$({ id, filter, date, days }: any) {
    const params = new HttpParams({
      fromObject: omitBy(
        { filter, date, days },
        (v) => v === undefined
      )
    });

    return this.#http.get<any>(
      `/api/client-service/${id}/data?${params.toString()}`
    );
  }

  loadAggregatedStatistics$({ id, dateFrom, dateTo }: any) {
    const params = new HttpParams({
      fromObject: omitBy(
        { dateFrom, dateTo },
        (v) => v === undefined
      )
    });

    return this.#http.get<any>(
      `/api/client-service/${id}/data-aggregation?${params.toString()}`
    );
  }

  recentDomainExistenceCheck$(id: string, cdnDomain: string) {
    return this.#http.get<CdnDomain>(`/api/client-service/${id}/domain-recent-deleted?domainName=${cdnDomain}`);
  }

}

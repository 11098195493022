export enum RouteKeys {
  DomainDetail = 'domain-detail'
}

export enum Roles {
  Authorized = 'authorized',
  Unauthorized = 'unauthorized'
}

export enum ApiEntityKeys {
  Domain = 'domain',
  DataDomain = 'data-domain',
  User = 'user',
  Alias = 'alias',
  ClientUser = 'client-user',
  NodeTaskQueueConsolidated = 'node-task-queue-consolidated'
}

export enum StatisticsFilters {
  TODAY = 'TODAY',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  BF_LAST_MONTH = 'BF_LAST_MONTH',
  LAST_365_DAYS = 'LAST_365_DAYS',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  X_DAYS_FROM = 'X_DAYS_FROM'
}

export const DEFAULT_DOMAIN_STATISTICS_RANGE = StatisticsFilters.LAST_365_DAYS;
export const DEFAULT_CLIENT_SERVICE_STATISTICS_RANGE = StatisticsFilters.LAST_365_DAYS;

<vshcdn-app-bar></vshcdn-app-bar>

<vshcdn-add-domain-dialog></vshcdn-add-domain-dialog>

<router-outlet></router-outlet>

<vshcdn-pop-error
  *zefPopErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</vshcdn-pop-error>

<vshcdn-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</vshcdn-snack-error>

<vshcdn-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance">
</vshcdn-snack-success>

<vshcdn-snack-warning
  *zefSnackOutlet="let data = data; let instance = instance; type: 'warning'"
  [data]="data"
  [instance]="instance">
</vshcdn-snack-warning>

<vshcdn-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance">
</vshcdn-snack-info>

<vshcdn-dialog-error
  *zefDialogErrorOutlet="let data = data"
  [data]="data">
</vshcdn-dialog-error>

<vshcdn-login></vshcdn-login>

<vshcdn-warm-cache-dialog></vshcdn-warm-cache-dialog>

<vshcdn-purge-cache-dialog></vshcdn-purge-cache-dialog>

<vshcdn-delete-domain-dialog></vshcdn-delete-domain-dialog>

<vshcdn-edit-domain-dialog></vshcdn-edit-domain-dialog>

<vshcdn-purge-log-dialog></vshcdn-purge-log-dialog>

<vshcdn-warm-log-dialog></vshcdn-warm-log-dialog>

<vshcdn-domain-existence-check-dialog></vshcdn-domain-existence-check-dialog>

import {
  ActionReducerMap,
  MetaReducer,
  ActionReducer
} from '@ngrx/store';
import { storeLogger } from 'ngrx-store-logger';
import { environment } from '../environments/environment';
import { AppState } from './app.model';

export function logger(reducer: ActionReducer<AppState>): any {
  return storeLogger({
    collapsed: true,
      filter: {
        blacklist: [ '@ngrx/store/update-reducers' ]
      }
  })(reducer);
}

export const appReducers: ActionReducerMap<AppState> = {};

export const metaReducers: MetaReducer<AppState>[] = (!environment.production)
  ? [ logger ]
  : [];

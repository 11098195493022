import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import { onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef';
import { UserEntity } from '@vshosting/cdn/core';
import { NodeTaskQueueConsolidatedEntity } from './node-task-queue-consolidated.entity';

@Injectable()
export class NodeTaskQueueConsolidatedEffect {

  #userEntity = inject(UserEntity);
  #nodeTaskQueueConsolidatedEntity = inject(NodeTaskQueueConsolidatedEntity);
  #actions$ = inject(Actions);

  setupDomainUpdateStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#nodeTaskQueueConsolidatedEntity.updateSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' },
      {
        search: [
          {
            name: 'action',
            operator: 'in',
            value: [ 'WARM', 'PURGE' ]
          }
        ]
      }
    )
  )));

  onDomainUpdateChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#nodeTaskQueueConsolidatedEntity)
  ));

}

import { unbox } from 'ngrx-forms';

import { locationSeriesReducer } from '@vshosting/components';
import { EditDomainFormValue } from './components/edit-domain-form';

const remoteLogPrefixes: Record<string, string> = {
  SYSLOG: "syslog://",
  RABBIT_MQ: "amqp://"
};

export const cleanupRemoteLogData = (v: EditDomainFormValue) => {
  if (v.enableRemoteLog === false || !v.remoteLogDestination.trim()) {
    return {
      ...v,
      remoteLogType: null,
      remoteLogDestination: null
    };
  }

  if (v.remoteLogType && v.remoteLogDestination) {
    const prefix = remoteLogPrefixes[v.remoteLogType];
    if (prefix && !v.remoteLogDestination.startsWith(prefix)) {
      return {
        ...v,
        remoteLogDestination: prefix + v.remoteLogDestination
      };
    }
  }

  return v;
};

export const cleanupGeoIpData = (v: EditDomainFormValue) => {
  const unboxedCountries = unbox(v.geoIpCountries);
  if (v.enableGeoIp === false || unboxedCountries?.length === 0) {
    return {
      ...v,
      geoIpMode: null,
      geoIpCountries: null
    };
  }

  return {
    ...v,
    geoIpCountries: unbox(v.geoIpCountries)
  };
};

export const cleanupCacheData = (v: EditDomainFormValue) => {
  if (v.forceCache === false) {
    return {
      ...v,
      forceCacheTime: 0
    }
  }

  return v;
};

export const cleanupLocations = (v: EditDomainFormValue) => {
  return {
    ...v,
    location: unbox(v.locations)?.reduce(locationSeriesReducer, 0)
  }
};

import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

import { ZefSnackInput } from '@zerops/zef';

@Component({
  standalone: true,
  selector: 'vshcdn-snack-warning',
  templateUrl: './snack-warning.feature.html',
  styleUrls: [ './snack-warning.feature.scss' ],
  imports: [
    NgIf,
    MatButtonModule,
    TranslocoModule
  ]
})
export class SnackWarningFeature {
  @Input()
  data: ZefSnackInput;

  @Input()
  instance: MatSnackBarRef<any>;
}

import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import invert from 'invert-color';
import { Md5 } from 'ts-md5';

@Component({
  standalone: true,
  selector: 'vshc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  imports: [
    NgStyle
  ]
})
export class AvatarComponent {

  @Input()
  image: string;

  @Input()
  initialWordsCount: number;

  @Input()
  externalImage: string;

  @Input()
  set name(v: string) {
    if (!!v) {

      this.initials = this.initialWordsCount
        ? this.#getInitials(v.replace(/ .*/,''))
        : this.#getInitials(v);
    }

    this.#name = v;
  }
  get name() {
    return this.#name;
  }

  @Input()
  extension = 'jpg';

  @Input()
  set size(v) {
    this.#size = v;
    this.fontSizeFactor = v / 3;
  }
  get size() {
    return this.#size;
  }

  @Input()
  set email(v) {
    this.#email = v;
    if (v) {
      this.#hash = (<string> Md5.hashStr(v.trim().toLocaleLowerCase()));
      this.gravatarImage = `https://www.gravatar.com/avatar/${this.#hash}.${this.extension}?s=${(this.size * 1.5)}&d=identicon`;
    }
  }
  get email() {
    return this.#email;
  }

  @Input()
  set backgroundColor(v) {
    this.#backgroundColor = v;
    this.textColor = invert(v, true);
  }
  get backgroundColor() {
    return this.#backgroundColor;
  }

  @Input()
  borderWidth = 0;

  textColor: string;
  fontSizeFactor: number;
  initials: string;
  gravatarImage: string;
  #email: string;
  #hash: string;
  #size = 40;
  #name: string;
  #backgroundColor: string;

  #getInitials(name: string) {
    return name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join('');
  }

}

import { ApplicationRef, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import 'chartjs-adapter-date-fns';
import { environment } from '@vshosting/cdn/env';
import { appConfig, AppFeature } from './app';

interface IWindow {
  ngRef: ApplicationRef;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppFeature, appConfig)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    const nwWindow = (window as unknown) as IWindow;
    if (nwWindow.ngRef) {
      nwWindow.ngRef?.destroy();
    }
    nwWindow.ngRef = ref;
  })
  .catch((err) =>
    // Otherwise, log the boot error
    console.error(err)
  );

import { Injectable } from '@angular/core';
import { validate, FormState } from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';

import { ZefForm } from '@zerops/zef';
import { FEATURE_NAME } from './login.constant';

export interface LoginFormValue {
  email: string;
  password: string;
}

export type LoginFormState = FormState<LoginFormValue>;

@Injectable({ providedIn: 'root' })
export class LoginForm extends ZefForm<LoginFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        email: 'user@allroles.cz',
        password: '123456789'
      },
      {
        email: validate(required, email),
        password: validate(required)
      }
    );
  }
}

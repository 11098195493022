import { provideEffects } from '@ngrx/effects';
import { ClientServiceBaseEffect } from './client-service-base';
import { DomainBaseEffect } from './domain-base';
import { SettingsBaseEffect } from './settings-base';
import { AliasBaseEffect } from './alias-base';
import { DataDomainBaseEffect } from './data-domain-base';
import { NodeTaskQueueConsolidatedEffect } from './node-task-queue-consolidated-base';
import { UserBaseEffect } from './user-base';

export const CORE_EFFECTS = [
  provideEffects(ClientServiceBaseEffect),
  provideEffects(DomainBaseEffect),
  provideEffects(SettingsBaseEffect),
  provideEffects(AliasBaseEffect),
  provideEffects(DataDomainBaseEffect),
  provideEffects(NodeTaskQueueConsolidatedEffect),
  provideEffects(UserBaseEffect),
] as const;

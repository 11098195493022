import { HashMap } from '@zerops/zef';
import { CdnClientService, CdnClientServiceStatistic } from '@vshosting/models';
import { StatisticsFilters } from '@vshosting/cdn/app';

export class ClientServiceBaseState {
  data: CdnClientService;
  statistics: HashMap<CdnClientServiceStatistic[]>;
  aggregatedStatistics: {
    hits: number;
    miss: number;
    traffic: number;
  }
}

export interface TerminateTrialResponse {
  endOfTrial: string;
}

type ClientServiceStatisticsFilterTypeUnion = `${StatisticsFilters}`;

export interface LoadClientServiceStatisticsPayload extends Record<PropertyKey, unknown> {
  id: string;
  filter?: ClientServiceStatisticsFilterTypeUnion;
  days?: number;
  date?: string;
}

export interface LoadClientServiceStatisticsAggregatedPayload {
  dateFrom: string;
  dateTo: string;
  hits: number;
  miss: number;
  traffic: number;
}

export interface LoadClientServiceStatisticsResponse {
  data: CdnClientServiceStatistic[];
}

export interface LoadClientServiceStatisticsAggregatedResponse {
  hits: number;
  miss: number;
  traffic: number;
}


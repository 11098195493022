<mat-card class="__card  z-manual-elevation  c-soft-elevation">

  <h4 class="__title">
    Oof, something went wrong …
  </h4>

  <div class="__content">
    <ng-container *ngFor="let item of data; trackBy: trackBy;">

      <div>
        {{ item.message }}
      </div>

      <ng-container
        *ngIf="item.originalError?.error?.error?.meta?.length">
        <div
          *ngFor="let meta of item.originalError?.error?.error?.meta"
          class="__meta">

          <span *ngIf="meta.parameter">{{ meta.parameter }}</span>

          <ng-container *ngIf="meta.parameter"> - </ng-container>

          <span *ngIf="meta.message">{{ meta.message }}</span>

        </div>
      </ng-container>

    </ng-container>
  </div>

  <button
    mat-button
    class="__button  c-button--full"
    (click)="instance.close()">
    <mat-icon class="__button_icon">report</mat-icon> Okay, close.
  </button>

</mat-card>
import { inject } from '@angular/core';
import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn
} from '@angular/common/http';

import { API_URL } from './api-interceptor.constant';

export const apiInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const _apiUrl = inject(API_URL);
  if (request.url.startsWith('/api') && !request.url.includes(_apiUrl)) {
    request = request.clone({
      url: _apiUrl + (request.url.replace('/api', ''))
    });
  }
  return next(request);
}

import { ValidationErrors } from 'ngrx-forms';
import memoize from 'lodash-es/memoize';
import uniq from 'lodash-es/uniq';
import * as Fuse from 'fuse.js';
import differenceInHours from 'date-fns/differenceInHours';

import { HashMap } from '@zerops/zef';
import { Country } from '@vshosting/models';

export function formatBytes(bytes: number, decimals = 2): string {
  if (!+bytes) return '0 B'

  if (bytes < 1) return `0 B`

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const fuseHighlight = <T extends { id: string; }>(
  fuseSearchResult: Fuse.FuseResult<T>[],
  highlightClassName = 'c-fuse-highlight'
) => {

  const generateHighlightedText = (inputText: string, regions: readonly Fuse.RangeTuple[]) => {
    let content = '';
    let nextUnhighlightedRegionStartingIndex = 0;

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1;

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<span class="${highlightClassName}">`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</span>',
      ].join('');

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    content += inputText.substring(nextUnhighlightedRegionStartingIndex);

    return content;
  };

  const _matchMap: HashMap<number> = {};
  return fuseSearchResult
    .filter(({ matches }) => matches && matches.length)
    .reduce((acc, { item, matches }) => {
      const id = item.id
      if (_matchMap[item.id] === undefined) {
        acc.push({ id });
        _matchMap[id] = acc.length - 1;
      }

      matches.forEach(({ key, value, indices }) => {
        acc[_matchMap[id]][key] = generateHighlightedText(value, indices);
      });

      return acc;
    }, [] as { [key: string]: string }[]);
};


const URL_REGEX = /^https?:\/\/([\w-]+\.)+[\w]{2,}(\/\S*)?$/i;

export const DOMAIN_REGEX = new RegExp('^[a-zA-Z0-9]+([\\-.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,20}(:[0-9]{1,5})?$');

export const SUBDOMAIN_REGEX = new RegExp('^(?!-)(?!.*--)[a-zA-Z0-9-]+(?<!-)$')
;
export const SANITIZE_DOMAIN_REGEX = /[^a-zA-Z0-9]/g;

export function validateUrlsCreator(max: number) {
  function validateUrls(urls: string): ValidationErrors {
    if (!urls) {
      return {};
    }

    const lines = urlsCleanup(urls);

    if (lines.length > max) {
      return {
        validateUrls: {
          actual: urls,
          message: 'urlsInvalidLength'
        }
      };
    }

    for (const url of lines) {
      if (!URL_REGEX.test(url)) {
        return {
          validateUrls: {
            actual: urls,
            message: 'urlInvalidFormat'
          }
        };
      }
    }

    return {};
  }

  return memoize(validateUrls);
}

export const countryMapReducer = (obj: HashMap<Country>, itm: Country) => {
  obj[itm.id] = itm;
  return obj;
};

export const locationSeriesReducer = (acc: number, id: number) => {
  acc = acc + id;
  return acc;
};


export const urlsCleanup = (urls: string): string[] => {
  if (!urls) { return []; }
  return uniq([ ...urls.split('\n').filter((line) => !!line) ]);
}

export function formatTooltipTitleDates(
  dateFrom: string,
  dateTill: string,
  locale: Locale
): string {
  const fromDate = new Date(dateFrom);
  const tillDate = new Date(dateTill);
  const hoursDifference = differenceInHours(tillDate, fromDate);

  let formatOptions: Intl.DateTimeFormatOptions;

  if (hoursDifference <= 24) {
    // Show date and time
    formatOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  } else if (hoursDifference <= 24 * 31) {
    // Show date without time
    formatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  } else {
    // Show date with year and month
    formatOptions = { year: 'numeric', month: 'short' };
  }

  const formatter = new Intl.DateTimeFormat(locale.code, formatOptions);
  return `${formatter.format(fromDate)} - ${formatter.format(tillDate)}`;
}

export function generateStrongPassword(): string {
  const length = Math.floor(Math.random() * 7) + 18;

  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberChars = '0123456789';
  const allChars = lowerCaseChars + upperCaseChars + numberChars;

  let password = '';

  password += lowerCaseChars.charAt(Math.floor(Math.random() * lowerCaseChars.length));
  password += upperCaseChars.charAt(Math.floor(Math.random() * upperCaseChars.length));
  password += numberChars.charAt(Math.floor(Math.random() * numberChars.length));

  for (let i = password.length; i < length; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
}

export function b64DecodeUnicode(str: string) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c: string) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}


<div class="__avatar">
  <vshc-avatar
    [name]="name"
    [email]="email"
    [size]="80"
    [backgroundColor]="'#4c575c'">
  </vshc-avatar>
</div>

<h3 class="__name">{{ name }}</h3>

<span class="__email">{{ email }}</span>

<ng-content />

<div class="__logout">
  <button
    class="__logout-button  c-button--smaller"
    mat-stroked-button
    type="button"
    (click)="logout.emit()">
    <mat-icon>logout</mat-icon>&nbsp;<span>{{ translations?.logout }}</span>
  </button>
</div>

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AliasAddRecordResponse } from './alias-base.model';

@Injectable({ providedIn: 'root' })
export class AliasBaseApi {
  #http = inject(HttpClient);

  aliasAddRecord$(id: string) {
    return this.#http.put<AliasAddRecordResponse>(
      `/api/alias/${id}/add-records`,
      {
        recordType: [ 'CNAME' ]
      }
    );
  }

}

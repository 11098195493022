<zef-dialog
  #dialogRef
  [open]="open"
  (closeTrigger)="closeTrigger.emit()"
  [paneClass]="'c-dialog-no-padding'"
  [options]="{
    maxWidth: '95vw',
    restoreFocus:false
  }"
  [autoFocus]="false">
  <div class="__wrap">

    <zef-scroll [height]="'100%'" [maxHeight]="'90vh'">
      <div class="c-text-content  c-text-content-pitch  __content">

        <div class="__info">
          @if (!!heading) {
            <h2
              class="__heading"
              [innerHTML]="heading">
            </h2>
          }
          <div class="__desc-wrap">
            @if (!!description) {
              <p
                class="__desc"
                [innerHTML]="description">
              </p>
            }
          </div>
        </div>

        <ng-content />

      </div>
    </zef-scroll>

    <button
      type="button"
      class="__close-button"
      mat-icon-button
      (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>

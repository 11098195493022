import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AvatarComponent } from '../avatar';

@Component({
  standalone: true,
  selector: 'vshc-active-user-info',
  templateUrl: './active-user-info.component.html',
  styleUrls: [ './active-user-info.component.scss' ],
  imports: [
    MatButtonModule,
    MatIconModule,
    AvatarComponent
  ]
})
export class ActiveUserInfoComponent {

  @Input()
  name: string;

  @Input()
  email: string;

  @Input()
  translations: any;

  @Output()
  logout = new EventEmitter<void>();

}

import { provideEffects } from '@ngrx/effects';
import {
  AliasesEffect,
  AddDomainDialogEffect,
  DeleteDomainDialogEffect,
  DomainExistenceCheckDialogEffect,
  EditDomainDialogEffect,
  PurgeCacheDialogEffect,
  PurgeLogDialogEffect,
  WarmCacheDialogEffect,
  WarmLogDialogEffect
} from '@vshosting/cdn/features';

export const FEATURES_EFFECTS = [
  provideEffects(AliasesEffect),
  provideEffects(AddDomainDialogEffect),
  provideEffects(DeleteDomainDialogEffect),
  provideEffects(DomainExistenceCheckDialogEffect),
  provideEffects(EditDomainDialogEffect),
  provideEffects(PurgeCacheDialogEffect),
  provideEffects(PurgeLogDialogEffect),
  provideEffects(WarmCacheDialogEffect),
  provideEffects(WarmLogDialogEffect)
] as const;

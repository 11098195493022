import { Injectable } from '@angular/core';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { ZefForm } from '@zerops/zef';
import { AddAliasFormValue } from './components/add-alias-form';
import { FEATURE_NAME } from './aliases.constant';

@Injectable({ providedIn: 'root' })
export class AliasesForm extends ZefForm<AddAliasFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        aliasDomain: null
      },
      {
        aliasDomain: validate(required)
      }
    );
  }
}

import { Injectable } from '@angular/core';

import { EntityService } from '@zerops/zef';
import { CdnDomain } from "@vshosting/models";
import { ApiEntityKeys } from '@vshosting/cdn/app';
import { DomainAddPayload, DomainUpdatePayload } from './domain-base.model';

@Injectable({ providedIn: 'root' })
export class DomainEntity extends EntityService<CdnDomain, DomainAddPayload, DomainUpdatePayload> {
  constructor() {
    super(ApiEntityKeys.Domain);
  }
}

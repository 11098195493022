import { Injectable } from '@angular/core';

import { FEATURE_NAME } from './user-base.constant';

const userStorageKey = FEATURE_NAME;

interface UserDataPayload {
  userId?: string;
  clientUserId?: string;
  clientServiceId?: string;
}

@Injectable({ providedIn: 'root' })
export class UserDataStorageService {
  #data?: UserDataPayload;

  setData(data: UserDataPayload) {
    this.#data = data;
    this.#updateStorage();
  }

  removeData() {
    this.#data = undefined;
    this.#updateStorage();
  }

  getData() {
    return this.#data;
  }

  constructor() {
    this.#data = this.#getStorage();
  }

  #updateStorage() {
    const d = this.#data
      ? JSON.stringify(this.#data)
      : '';

    localStorage.setItem(userStorageKey, d);
  }

  #getStorage() {
    const s = localStorage.getItem(userStorageKey);

    return s ? JSON.parse(s) : undefined;
  }
}

import { version } from './version';

export const environment = {
  production: true,
  authWebsiteUrl: 'https://client.hu.dev.kube.vsh-labs.cz',
  cdnApiUrl: 'https://cdn.hu.dev.kube.vsh-labs.cz/api/public',
  clientZoneApi: 'https://client.hu.dev.kube.vsh-labs.cz/api/public',
  authApiUrl: 'https://client.hu.dev.kube.vsh-labs.cz/api/public/auth',
  websocketAuthUrl: 'https://cdn.hu.dev.kube.vsh-labs.cz/api/public/web-socket/login',
  websocketApiPath: 'api/public/web-socket',
  websocketHost: 'cdn.hu.dev.kube.vsh-labs.cz',
  cdnHost: 'vshcdn.net',
  maxWarmCacheUrls: '20',
  maxPurgeUrls: '20',
  sentryDsn: '%sentryDsn%%%',
  sentryEnvName: '%%sentryEnvName%%',
  version: version
};

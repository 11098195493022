import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_NAME } from './aliases.constant';
import { AliasesFeatureState } from './aliases.model';

export const selectAliasFeatureState = createFeatureSelector<AliasesFeatureState>(FEATURE_NAME);

export const selectActiveAliasFeatureId = createSelector(
  selectAliasFeatureState,
  (state) => state.id
);

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

import { ZefReactiveComponentBase, ZefError, zefLogout } from '@zerops/zef';
import { userBaseActions } from '@vshosting/cdn/core';

@Component({
  standalone: true,
  selector: 'vshcdn-dialog-error',
  templateUrl: './dialog-error.feature.html',
  styleUrls: [ './dialog-error.feature.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatCardModule
  ]
})
export class DialogErrorFeature extends ZefReactiveComponentBase {

  // # Event Streams
  onLogout$ = new Subject<void>();

  // # Data
  // -- sync
  userLoadKey = userBaseActions.loadUser.type;

  // -- angular
  @Input()
  data: ZefError[];

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(
    map(() => zefLogout())
  );

  constructor() {
    super();

    this.$dispatchActions([ this._logoutAction$ ]);
  }
}

<div class="__grid" *ngIf="!!data && !!translations">

  <!-- locations -->
  <div class="__item-wrap">
    <div class="__item is-active">
      <h4 class="c-info-item_label">{{ translations?.locations }}</h4>
      <div class="c-info-item_value  is-list">
        <span
          class="__location"
          *ngFor="let location of data.locations; let last = last;">
          {{ location.name }}<span class="__location_separator" *ngIf="!last">, </span>
        </span>
      </div>
    </div>
  </div>

  <!-- cache -->
  <div class="__item-wrap">
    <div class="__item" [class.is-active]="!!data.forceCache">

      <h4 class="c-info-item_label">{{ translations?.forceCache }}</h4>

      <div class="c-info-item_value">

        <button
          (mouseenter)="data.forceCache ? openPopover('forceCachePopover', cachePopRef) : undefined"
          (mouseleave)="data.forceCache ? closePopover('forceCachePopover', cachePopRef) : undefined"
          type="button"
          [satPopoverAnchor]="cachePopRef"
          class="c-info-item_badge"
          [class.is-active]="!!data.forceCache">
          {{ generalTranslations[data.forceCache ? 'active' : 'inactive'] }}
          <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        </button>

        <sat-popover #cachePopRef [verticalAlign]="'below'">
          <mat-card
            (mouseenter)="data.forceCache ? openPopover('forceCachePopover', cachePopRef) : undefined"
            (mouseleave)="data.forceCache ? closePopover('forceCachePopover', cachePopRef) : undefined"
            class="mat-elevation-z20  mat-mdc-elevation-specific  __card">
            <div class="c-info-item_more">
              <h4 class="c-info-item_more-title">{{ translations.forceCacheTimeMoreTitle }}</h4>
              <div>
                <span class="c-info-item_more-value">{{ data.forceCacheTime }} s</span>
              </div>
            </div>
          </mat-card>
        </sat-popover>

      </div>
    </div>
  </div>

  <!-- webp -->
  <div class="__item-wrap">
    <div class="__item" [class.is-active]="!!data.webp">
      <h4 class="c-info-item_label">{{ translations?.webp }}</h4>
      <div class="c-info-item_value">

        <button
          (mouseenter)="data.webp ? openPopover('webpPopover', webpPopRef) : undefined"
          (mouseleave)="data.webp ? closePopover('webpPopover', webpPopRef) : undefined"
          type="button"
          [satPopoverAnchor]="webpPopRef"
          class="c-info-item_badge"
          [class.is-active]="!!data.webp">
          {{ generalTranslations[data.webp ? 'active' : 'inactive'] }}
          <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        </button>

        <sat-popover #webpPopRef [verticalAlign]="'below'">
          <mat-card
            (mouseenter)="data.webp ? openPopover('webpPopover', webpPopRef) : undefined"
            (mouseleave)="data.webp ? closePopover('webpPopover', webpPopRef) : undefined"
            class="mat-elevation-z20  mat-mdc-elevation-specific  __card">
            <div class="c-info-item_more" *ngIf="!!data.webp">
              <h4 class="c-info-item_more-title">{{ translations?.imgApiKey }}</h4>
              <div class="c-info-item_more-value">{{ data.imgApiKey }}</div>
              <h4 class="c-info-item_more-title">{{ translations?.imgApiSalt }}</h4>
              <div class="c-info-item_more-value">{{ data.imgApiSalt }}</div>
            </div>
          </mat-card>
        </sat-popover>

      </div>
    </div>
  </div>

  <!-- geoip -->
  <div class="__item-wrap">
    <div class="__item" [class.is-active]="!!data.geoIpCountries?.length">
      <h4 class="c-info-item_label">{{ translations?.geoIpCountries }}</h4>
      <div class="c-info-item_value">

        <button
          (mouseenter)="data.geoIpCountries?.length ? openPopover('geoIpCountriesPopover', geoIpPopRef) : undefined"
          (mouseleave)="data.geoIpCountries?.length ? closePopover('geoIpCountriesPopover', geoIpPopRef) : undefined"
          type="button"
          [satPopoverAnchor]="geoIpPopRef"
          class="c-info-item_badge"
          [class.is-active]="!!data.geoIpCountries?.length">
          {{ generalTranslations[!!data.geoIpCountries?.length ? 'active' : 'inactive'] }}
          <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        </button>

        <sat-popover #geoIpPopRef [verticalAlign]="'below'">
          <mat-card
            (mouseenter)="data.geoIpCountries?.length ? openPopover('geoIpCountriesPopover', geoIpPopRef) : undefined"
            (mouseleave)="data.geoIpCountries?.length ? closePopover('geoIpCountriesPopover', geoIpPopRef) : undefined"
            class="mat-elevation-z20  mat-mdc-elevation-specific  __card">
            <div class="c-info-item_more" *ngIf="!!data.geoIpCountries?.length">
              <h4 class="c-info-item_more-title">{{ translations?.geoIpMode }}</h4>
              <div class="c-info-item_more-value">{{ data?.geoIpMode }}<br/>{{ data.geoIpCountries }}</div>
            </div>
          </mat-card>
        </sat-popover>

      </div>
    </div>
  </div>

  <!-- secure connection -->
  <!-- <div class="__item-wrap">
    <div class="__item" [class.is-active]="!!data.checkBackendCertificate">
      <h4 class="c-info-item_label">{{ translations?.checkBackendCertificate }}</h4>
      <div class="c-info-item_value">

        <span
          class="c-info-item_badge"
          [class.is-active]="!!data.checkBackendCertificate">
          {{ generalTranslations[data.checkBackendCertificate ? 'active' : 'inactive'] }}
        </span>

      </div>
    </div>
  </div> -->

  <!-- remote log -->
  <div class="__item-wrap">
    <div class="__item" [class.is-active]="!!data.remoteLogDestination">
      <h4 class="c-info-item_label">{{ translations?.remoteLogTitle }}</h4>
      <div class="c-info-item_value">

        <button
          (mouseenter)="data.remoteLogDestination ? openPopover('remoteLogDestinationPopover', remoteLogPopRef) : undefined"
          (mouseleave)="data.remoteLogDestination ? closePopover('remoteLogDestinationPopover', remoteLogPopRef) : undefined"
          type="button"
          [satPopoverAnchor]="remoteLogPopRef"
          class="c-info-item_badge"
          [class.is-active]="!!data.remoteLogDestination">
          {{ generalTranslations[!!data.remoteLogDestination ? 'active' : 'inactive'] }}
          <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        </button>

        <sat-popover #remoteLogPopRef [verticalAlign]="'below'">
          <mat-card
            (mouseenter)="data.remoteLogDestination ? openPopover('remoteLogDestinationPopover', remoteLogPopRef) : undefined"
            (mouseleave)="data.remoteLogDestination ? closePopover('remoteLogDestinationPopover', remoteLogPopRef) : undefined"
            class="mat-elevation-z20  mat-mdc-elevation-specific  __card">
            <div class="c-info-item_more" *ngIf="!!data.remoteLogDestination">
              <h4 class="c-info-item_more-title">{{ translations?.remoteLogDestination }}</h4>
              <div class="c-info-item_more-value">{{ data.remoteLogType }} / {{ data.remoteLogDestination }}</div>
            </div>
          </mat-card>
        </sat-popover>

      </div>
    </div>
  </div>

</div>
export class Country {
  nameJsonObject: { [key: string]: string; };
  id: string;
}

export class Currency {
  id: string;
  symbol: string;
  displaySymbolBefore: boolean;
  roundDigits: number;
}

export class Language {
  id: string;
  name: string;
}

export class Enum {
  name: string;
  values: string[];
  default: string;
}

export enum GeoIpMode {
  BLACKLIST = 'BLACKLIST',
  WHITELIST = 'WHITELIST'
}

export enum RemoteLogType {
  SYSLOG = 'SYSLOG',
  RABBIT_MQ = 'RABBIT_MQ',
  LOGGLY = 'LOGGLY'
}

export interface Location {
  id: number;
  name: string;
}

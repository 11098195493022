import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsBaseApi {
  #http = inject(HttpClient);

  settings$() {
    return this.#http.get<any>(
      `/api/settings`
    );
  }

}

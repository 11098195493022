import { inject, Injectable } from '@angular/core';
import {
  catchError,
   map,
   of,
   switchMap,
   withLatestFrom
} from 'rxjs';
import { Actions,  createEffect, ofType } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';

import {
  onWebsocketMessageDispatchUpdateEntities,
  successOf,
  ZefSnackService
} from '@zerops/zef';
import { UserEntity } from '@vshosting/cdn/core';
import { AliasBaseActions } from './alias-base.actions';
import { AliasBaseApi } from './alias-base.api';
import { AliasEntity } from './alias-base.entity';

@Injectable()
export class AliasBaseEffect {
  // # Deps
  #actions$ = inject(Actions);
  #aliasEntity = inject(AliasEntity);
  #userEntity = inject(UserEntity);
  #aliasBaseApi = inject(AliasBaseApi)
  #snackService = inject(ZefSnackService);
  #translocoService = inject(TranslocoService);

  // # Data
  // -- async
  translations$ = this.#translocoService.selectTranslateObject('aliasBase');

  // # Effects
  setupDomainUpdateStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#aliasEntity.updateSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' }
    )
  )));

  onDomainUpdateChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#aliasEntity)
  ));

  onAliasAddRecord$ = createEffect(() => this.#actions$.pipe(
    ofType(AliasBaseActions.aliasAddRecord),
    switchMap((action) => this.#aliasBaseApi
      .aliasAddRecord$(action.data)
      .pipe(
        map((data) => AliasBaseActions.aliasAddRecordSuccess(data, action)),
        catchError((err) => of(AliasBaseActions.aliasAddRecordFail(err, action)))
      )
    )
  ));

  onAddAliasSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    successOf(this.#aliasEntity.addOne),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.aliasAdded }))
  ), { dispatch: false });

  onDeletedAliasSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    successOf(this.#aliasEntity.deleteOne),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.aliasRemoved }))
  ), { dispatch: false });

  onAliasAddRecordSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    successOf(this.#aliasEntity.updateOne),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.aliasAddReport }))
  ), { dispatch: false });

}

import {
    EnvironmentProviders,
    makeEnvironmentProviders
} from '@angular/core';

import { API_URL } from './api-interceptor.constant';

export const provideAppApi = (url: string): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: API_URL,
      useValue: url
    }
  ]);
}

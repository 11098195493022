<div class="__controls-grid">

  <div>
    <mat-button-toggle-group class="__type-toggle-group">
      <mat-button-toggle
        class="__type-toggle-button"
        [checked]="activeChart === item"
        (click)="activeChart = item"
        *ngFor="let item of chartTypesArr">
        <mat-icon fontSet="material-icons-outlined" class="__type-toggle-icon">{{ chartIconMap?.[item] }}</mat-icon>&nbsp;<span>{{ translations?.enums[item] }}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="u-text--center">

    <!-- cost labels -->
    <div class="__labels" *ngIf="activeChart === chartTypes.COST">
      <span class="__label" *ngFor="let item of costChartData?.datasets">

        <span class="__label-tag" [style.backgroundColor]="item.backgroundColor"></span>
        <span class="__label-name">{{ translations?.enums[item.label] }}</span>

        <span class="__label-value">
          <mat-icon fontSet="material-icons-outlined" class="__label-value-icon">functions</mat-icon><span class="__label-value-content">{{ sumData[item.label] | number }} {{ currency }}</span>
        </span>

      </span>
    </div>

    <!-- traffic labels -->
    <div class="__labels" *ngIf="activeChart === chartTypes.TRAFFIC">
      <span class="__label" *ngFor="let item of trafficChartData?.datasets">

        <span class="__label-tag" [style.backgroundColor]="item.backgroundColor"></span>
        <span class="__label-name">{{ translations?.enums[item.label] }}</span>

        <span class="__label-value">
          <mat-icon fontSet="material-icons-outlined" class="__label-value-icon">functions</mat-icon><span class="__label-value-content">{{ sumData[item.label] | formatBytes }}</span>
        </span>

      </span>
    </div>

  </div>

  <div class="u-text--right">
    <button
      (click)="popRef.open()"
      [satPopoverAnchor]="popRef"
      mat-stroked-button
      class="__range-toggle-button"
      type="button">
      <mat-icon fontSet="material-icons-outlined" class="__range-toggle-icon">date_range</mat-icon>
      {{ translations?.enums[activeFilter] }}
    </button>
  </div>

</div>

<div class="__canvas-wrap" *ngIf="activeChart === chartTypes.COST && !!chartOptions">
  <canvas
    class="__graph-canvas"
    baseChart
    [data]="costChartData"
    [options]="chartOptions[chartTypes.COST].options"
    [legend]="false"
    [type]="'bar'">
  </canvas>
</div>

<div class="__canvas-wrap" *ngIf="activeChart === chartTypes.TRAFFIC && !!chartOptions">
  <canvas
    class="__graph-canvas"
    baseChart
    [data]="trafficChartData"
    [options]="chartOptions[chartTypes.TRAFFIC].options"
    [legend]="false"
    [type]="'bar'">
  </canvas>
</div>

<sat-popover [hasBackdrop]="true" #popRef>
  <mat-card class="__date-toggle-card  mat-mdc-elevation-specific  mat-elevation-z24" *ngIf="!!chartOptions">
    <button
      mat-button
      class="__date-toggle-button"
      [class.is-active]="activeFilter === item"
      (click)="filterChange.emit(item)"
      *ngFor="let item of chartOptions?.[activeChart].filters">
      {{ translations?.enums[item] }}
    </button>
  </mat-card>
</sat-popover>
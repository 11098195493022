export const FEATURE_NAME = '@vshosting/cdn/core/client-service-base';

export enum ClientServicePriceListMetricIds {
  CdnDomains = 'CDNDOMAINS'
}

export const CLIENT_SERVICE_STATUS_COLOR_MAP = {
  ACTIVE: '#66BB6A',
  SUSPENDED: "#FFA726"
}

<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title"
  [description]="state.translations?.desc"
  (closeTrigger)="onClose$.next()">

  <div class="__wrap" *ngIf="state.meta?.data?.id">
    <div class="__content-wrap">

      <vshcdn-domain-info
        class="__domain-info"
        [data]="state.meta.data"
        [translations]="state.domainInfoTranslations"
        [generalTranslations]="state.generalTranslations">
      </vshcdn-domain-info>

      <zef-progress-error full [key]="updateActionKey">
        <button
          class="c-button--larger  c-button--full"
          (click)="onRenewDomain$.next()"
          mat-flat-button
          color="primary"
          type="button">
          {{ state.translations?.renewDomain }}
        </button>
      </zef-progress-error>

    </div>
  </div>
</vshc-dialog-wrapper>
/* eslint-disable @angular-eslint/no-input-rename */
import {
  Component,
  TemplateRef,
  Input,
  ContentChild,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';

import { HashMap, ZefReactiveComponentBase } from '@zerops/zef';
import { ItemListColumn } from './item-list.model';
import {
  TableComponent,
  ItemListColumnTemplateDirective,
  ItemListActionTemplateDirective,
  ItemListItemStateTemplateDirective
} from './components/table/table.component';

@Component({
  standalone: true,
  selector: 'vshc-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: [ './item-list.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    TableComponent
  ]
})
export class ItemListComponent<T> extends ZefReactiveComponentBase {

  colTemplatesMap: HashMap<TemplateRef<any>> = {};
  showAll$ = new BehaviorSubject<boolean>(false);

  @Input()
  items: T[];

  @Input()
  itemFlex: number;

  @Input()
  mode: 'data' | 'client' = 'client';

  @Input()
  itemFlexMd: number;

  @Input()
  itemFlexSm: number;

  @Input()
  itemFlexXs: number;

  @Input()
  isLoading: boolean;

  @Input()
  defaultSort: string;

  @Input()
  colsTranslations: HashMap<any>;

  @Input()
  customClassProperty: string;

  @Input()
  paginatorLength: number;

  @Input()
  paginatorPageSize: number;

  @Input()
  currentPage: number;

  @Input()
  defaultSortDirection: SortDirection;

  @Input()
  tableLink: string[];

  @Input()
  disableTableHover = false;

  @Input()
  columns: ItemListColumn[];

  @Input()
  trackBy: (a: number, b: any) => string | number;

  @Output()
  tableRowClicked = new EventEmitter<any>();

  @Output()
  page = new EventEmitter<PageEvent>();

  @Output()
  sorted = new EventEmitter<Sort>();

  @ContentChild(ItemListItemStateTemplateDirective, { read: TemplateRef })
  itemStateTemplateRef: TemplateRef<ItemListItemStateTemplateDirective>;

  @ContentChildren(ItemListColumnTemplateDirective)
  set cellTemplates(v) {
    this.#colTemplates = v;

    if (v) {
      v.forEach((col) => {
        this.colTemplatesMap[col.vshcItemListColumnTemplate] = col.templateRef;
      });
    } else {
      this.colTemplatesMap = {};
    }
  }
  get cellTemplates() {
    return this.#colTemplates;
  }

  @ContentChildren(ItemListActionTemplateDirective, { read: TemplateRef })
  actionTemplates: QueryList<TemplateRef<any>>;

  #colTemplates: QueryList<ItemListColumnTemplateDirective<T>>;

}

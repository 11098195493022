import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vshCdnHostWrapper',
  pure: true,
  standalone: true
})
export class VshCdnHostWrapperPipe implements PipeTransform {
  transform(value: string, replacer: string): string {
    const wrappedText = `<span class="c-cdn-host-wrapper">${replacer}</span>`;
    return value.split(replacer).join(wrappedText);
  }
}

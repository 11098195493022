import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on
} from '@ngrx/store';

import {
  zefResetState,
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef';
import { VshUser } from '@vshosting/models'
import { FEATURE_NAME } from './user-base.constant';
import { UserBaseState } from './user-base.model';

export const userBaseActions = createActionGroup({
  source: FEATURE_NAME,
  events: {
    'set user id': (id?: string) => ({ id }),

    'clear user data': emptyProps(),

    'load user': (obj?: any) => zefActionPayload<any>(
      obj ? { ...obj } : undefined,
      { type: 'dialog' },
    ),
    'load user fail': zefActionFailPayload,
    'load user success': (data: any, originalAction: any, meta: any) => zefActionSuccessPayload<VshUser>()(
      data,
      originalAction,
      meta
    ),

    'store user data': (
      userId?: string,
      clientUserId?: string,
      clientServiceId?: string,
      refresh?: boolean
    ) => ({ clientUserId, clientServiceId, userId, refresh: !!refresh }),
    'store user data success': (
      userId?: string,
      clientUserId?: string,
      clientServiceId?: string,
      refresh?: boolean
    ) => ({ clientUserId, clientServiceId, userId, refresh: !!refresh }),

    'load stored user data': emptyProps(),
    'load stored user data success': (
      userId?: string,
      clientUserId?: string,
      clientServiceId?: string
    ) => ({ clientUserId, userId, clientServiceId }),

  }
});

const initialState = new UserBaseState();

export const userBaseState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(
      userBaseActions.setUserId,
      (s, { id }): UserBaseState => ({
        ...s,
        activeUserId: id
      })
    ),
    on(
      userBaseActions.clearUserData,
      (s): UserBaseState => ({
        ...s,
        activeUserId: undefined,
        activeClientUserId: undefined,
        activeClientServiceId: undefined
      })
    ),
    on(
      userBaseActions.loadStoredUserData,
      (s): UserBaseState => ({
        ...s
      })
    ),
    on(
      userBaseActions.storeUserData,
      userBaseActions.loadStoredUserDataSuccess,
      (s, { userId, clientUserId, clientServiceId }): UserBaseState => ({
        ...s,
        activeUserId: userId,
        activeClientUserId: clientUserId,
        activeClientServiceId: clientServiceId
      })
    ),
    on(zefResetState, () => initialState)
  )
});

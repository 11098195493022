<div
  class="__wrap"
  [ngClass]="{
    'is-vertical': orientation === 'vertical',
    'is-horizontal': orientation === 'horizontal',
    'is-center': position === 'center'
  }">
  <div
    class="__sep  c-separator-sep"
    [ngClass]="{
      'is-vertical': orientation === 'vertical',
      'is-horizontal': orientation === 'horizontal',
      'size-is-mini': size === 'mini',
      'size-is-small': size === 'small',
      'size-is-medium': size === 'medium',
      'size-is-large': size === 'large',
      'spacing-is-mini': spacing === 'mini',
      'spacing-is-small': spacing === 'small',
      'spacing-is-medium': spacing === 'medium',
      'spacing-is-large': spacing === 'large',
      'spacing-is-huge': spacing === 'huge',
      'spacing-is-humongous': spacing === 'humongous',
      'spacing-is-big': spacing === 'big',
      'spacing-is-disabled': spacing === 'disabled',
      'is-center': position === 'center',
      'is-start': position === 'start',
      'is-end': position === 'end'
    }">
  </div>
</div>

/* eslint-disable @angular-eslint/no-input-rename */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  EventEmitter,
  Output,
  Directive,
  inject,
  QueryList
} from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet
} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatTableModule } from '@angular/material/table';
import { Sort, SortDirection, MatSortModule } from '@angular/material/sort';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import orderBy from 'lodash-es/orderBy';
import { HashMap } from '@zerops/zef';
import {
  ItemListColumn,
  ItemListColumnTypes
} from '../../item-list.model';
import { createCustomSorter } from '../../item-list.utils';
import { GetPropertyByString } from '../get-prop-by-string';
import { TypeSafeMatCellDef } from '../type-safe-mat-cell-def';

export function getPropertyByStringPath(object: any, path: string): any {
  try {
    return path.split('.').reduce((obj, p) => obj[p], object);
  } catch (err) {
    return undefined;
  }
}

class TableDataSource<T> extends MatTableDataSource<T> {}

@Directive({
  standalone: true,
  selector: '[vshcItemListColumnTemplate]'
})
export class ItemListColumnTemplateDirective<T> {

  templateRef: TemplateRef<T> = inject(TemplateRef);

  @Input()
  vshcItemListColumnTemplate: string;

}

@Directive({
  standalone: true,
  selector: '[vshcItemListActionTemplate]'
})
export class ItemListActionTemplateDirective {
  templateRef = inject(TemplateRef);
}

@Directive({
  standalone: true,
  selector: '[vshcItemListItemStateTemplate]'
})
export class ItemListItemStateTemplateDirective {
  templateRef = inject(TemplateRef);
}

@Component({
  standalone: true,
  selector: 'vshc-item-list-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    TranslocoLocaleModule,
    GetPropertyByString,
    TypeSafeMatCellDef
  ]
})
export class TableComponent<T> {

  // # Deps
  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);

  // # Data
  // -- async
  sorted$ = new BehaviorSubject<Sort>(undefined);
  defaultSort$ = new BehaviorSubject<string>(undefined);
  items$ = new BehaviorSubject<T[]>([]);
  defaultSortDirection$ = new BehaviorSubject<SortDirection>(undefined);
  currentPage$ = new BehaviorSubject<number>(1);

  // -- sync
  dataSource = new TableDataSource<T>();
  colTypes = ItemListColumnTypes;
  colNames: string[];
  colMap: HashMap<ItemListColumn> = {};
  limit$ = new BehaviorSubject<number>(0);

  // -- angular
  @Input()
  set items(value: T[]) {
    this.items$.next(value);
    this.#items = value;
  }
  get items() {
    return this.#items;
  }
  #items: T[];

  @Input()
  set defaultSort(value: string) {
    this.defaultSort$.next(value);
    this.#defaultSort = value;
  }
  get defaultSort(): string {
    return this.#defaultSort;
  }
  #defaultSort: string;

  @Input()
  set defaultSortDirection(value: SortDirection) {
    this.defaultSortDirection$.next(value);
    this.#defaultSortDirection = value;
  }
  get defaultSortDirection(): SortDirection {
    return this.#defaultSortDirection;
  }
  #defaultSortDirection: SortDirection;

  @Input()
  set limit(value: number) {
    this.limit$.next(value);
    this.#limit = value;
  }
  get limit(): number {
    return this.#limit;
  }
  #limit: number;

  @Input()
  set currentPage(value: number) {
    this.currentPage$.next(value);
    this.#currentPage = value;
  }
  get currentPage(): number {
    return this.#currentPage;
  }
  #currentPage: number;

  @Input()
  paginatorLength: number;

  @Input()
  set columns(v) {
    this.#columns = v;

    if (v && v.length) {
      this.colNames = v.map((c) => c.name);

      this.colMap = v.reduce((obj, item) => ({
        ...obj,
        [item.name]: item
      }), {});
    }
  }

  get columns() {
    return this.#columns;
  }

  @Input()
  linkPath: string[];

  @Input()
  paginatorPageSize: number;

  @Input()
  count: number;

  @Input()
  mode: 'client' | 'data' = 'client';

  @Input()
  customClassProperty: string;

  @Input()
  disableHover = false;

  @Input()
  trackBy: (a: number, b: any) => string | number;

  @Input()
  showRestrictionButtons: boolean;

  @Input()
  showAll: boolean;

  @Input()
  colsTranslations: HashMap<any>;

  @Input()
  columnTemplateMap: HashMap<TemplateRef<any>>;

  @Input()
  actionsTemplates: QueryList<TemplateRef<any>>;

  @Input()
  itemStateTemplate: TemplateRef<any>;

  @Output()
  sorted = new EventEmitter<Sort>();

  @Output()
  clicked = new EventEmitter<any>();

  @Output()
  page = new EventEmitter<PageEvent>();

  @Output()
  showChanged = new EventEmitter<boolean>();

  data$ = combineLatest([
    this.items$,
    this.sorted$,
    this.defaultSort$,
    this.defaultSortDirection$,
    this.limit$,
    this.currentPage$
  ]).pipe(
    tap(([ data, sort, defSort, defSortDir, limit, page ]) => {

      let res = [] as T[];

      if (sort && !!sort.direction && this.colMap[sort.active]) {

        if (this.colMap[sort.active].sort) {
          const customSorters = this.colMap[sort.active].sort.map((key) => createCustomSorter(key));

          const ordered = orderBy(
            data,
            customSorters,
            [ ...this.colMap[sort.active].sort.map(() => (sort.direction as any)) ]
          );

          res = this.mode === 'client' ? this._limit(ordered, limit, page) : ordered;

        } else if (this.colMap[sort.active].dataPath) {
          const ordered = orderBy(
            data,
            [ (d) => getPropertyByStringPath(d, this.colMap[sort.active].dataPath) ],
            [ (sort.direction as any) ]
          );

          res = this.mode === 'client' ? this._limit(ordered, limit, page) : ordered;
        }
      } else if (!!defSort && !!defSortDir) {
        const config = this.colMap[defSort];
        const sortKeys = config?.sort?.length ? config.sort : [ defSort ];
        const sortDirs = config?.sort?.length ? config.sort.map(() => defSortDir) : [ defSortDir ];
        const customSorters = sortKeys.map((key) => createCustomSorter(key));

        const ordered = orderBy(data, customSorters, sortDirs);
        res = this.mode === 'client' ? this._limit(ordered, limit, page) : ordered;
      } else {
        res = this.mode === 'client' ? this._limit(data, limit, page) : data;
      }

      this.dataSource.data = res;
    })
  );

  #columns: ItemListColumn[] = [];

  navigate(path: string[], id: string, disabled = false) {
    if (path && !disabled) {
      const route = path && path.length && id ? [ ...path, id ] : [];
      this.#router.navigate(route, { relativeTo: this.#activatedRoute });
    }
  }

  clickRow(element: any, event: MouseEvent) {
    this.clicked.emit(element);
    event.stopPropagation();
  }

  private _limit(arr: any[], limit: number, page: number) {
    const data = limit ? arr.slice(0, limit) : arr;
    if (page) {
      return this._page(data, page);
    }
    return data;
  }

  private _page(arr: any[], page: number) {
    return arr.slice(
      page === 1 ? 0 : ((page - 1) * this.paginatorPageSize),
      ((page - 1) * this.paginatorPageSize) + this.paginatorPageSize
    );
  }

}

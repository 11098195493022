import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { SatPopover } from '@zerops/zef';
import { SatPopoverModule } from '@zerops/zef';
import { CdnDomain } from '@vshosting/models';
import { StatusIconComponent } from '@vshosting/components';
import { DOMAIN_STATUS_COLOR_MAP } from '@vshosting/cdn/core';

@Component({
  standalone: true,
  selector: 'vshcdn-domain-info',
  templateUrl: './domain-info.component.html',
  styleUrls: [ './domain-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    MatCardModule,
    MatIconModule,
    StatusIconComponent,
    SatPopoverModule
  ]
})
export class DomainInfoComponent {

  domainStatusColorMap = DOMAIN_STATUS_COLOR_MAP;
  #popoverTimeouts: { [key: string]: any } = {};
  #popoverStates: { [key: string]: boolean } = {};

  @Input()
  data: CdnDomain;

  @Input()
  translations: any;

  @Input()
  generalTranslations: any;

  openPopover(popoverId: string, popoverRef: SatPopover): void {
    clearTimeout(this.#popoverTimeouts[popoverId]);
    this.#popoverStates[popoverId] = true;
    popoverRef.open();
  }

  closePopover(popoverId: string, popoverRef: SatPopover): void {
    this.#popoverTimeouts[popoverId] = setTimeout(() => {
      this.#popoverStates[popoverId] = false;
      popoverRef.close();
    }, 150);
  }

}

import { Injectable } from '@angular/core';

import { EntityService } from '@zerops/zef';
import { CdnNodeTaskQueueConsolidated } from "@vshosting/models";
import { ApiEntityKeys } from '@vshosting/cdn/app';

@Injectable({ providedIn: 'root' })
export class NodeTaskQueueConsolidatedEntity extends EntityService<CdnNodeTaskQueueConsolidated> {
  constructor() {
    super(ApiEntityKeys.NodeTaskQueueConsolidated);
  }
}

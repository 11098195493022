<mat-card class="__card  c-soft-elevation">
  <div class="__grid">

    <div class="__grid-item">

      <div *transloco="let t; read: 'exceptions'">

        <div>{{ t(data.code) || t('UNKNOWN_ERROR', { key: data.key}) }}</div>

      </div>

      <ng-container
        *ngIf="data.originalError?.error?.error?.meta?.length">
        <div
          *ngFor="let meta of data.originalError?.error?.error?.meta"
          class="__meta">

          <span *ngIf="meta.parameter">{{ meta.parameter }}</span>

          <ng-container *ngIf="meta.parameter"> - </ng-container>

          <span *ngIf="meta.message">{{ meta.message }}</span>

        </div>
      </ng-container>

    </div>

    <div class="u-text--right  __grid-item">
      <button
        class="__button"
        mat-button
        type="button"
        (click)="instance.closeWithAction()">
        {{ state.translations?.close }}
      </button>
    </div>

  </div>
</mat-card>

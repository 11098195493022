<vshc-dialog-wrapper
  [open]="state.open"
  (closeTrigger)="onClose$.next()"
  [heading]="state.translations?.title"
  [description]="state.translations?.desc">
  <div class="__wrap">
    <vshcdn-purge-cache-form
      #purgeCacheFormRef
      class="__form"
      (submit)="onPurgeCache$.next()"
      [state]="state.purgeCacheFormState"
      [translations]="state.translations"
      [formErrorTranslations]="state.formErrorTranslations">
      <zef-progress-error full [key]="purgeCacheActionKey">
        <button
          class="c-button--larger  c-button--full"
          (click)="purgeCacheFormRef.triggerSubmit()"
          mat-flat-button
          color="primary"
          type="button">
          {{ state.translations?.purgeCache }}
        </button>
      </zef-progress-error>
    </vshcdn-purge-cache-form>
  </div>
</vshc-dialog-wrapper>
import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { FEATURE_NAME } from './aliases.constant';
import { AliasesFeatureState } from './aliases.model';

export const AliasesFeatureActions = createActionGroup({
  source: '@vshapps/cdn/features/aliases',
  events: {
    'set id': props<{ id: string }>(),
    'reset id': emptyProps()
  }
});

const initialState = new AliasesFeatureState();

export const aliasesFeatureState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(AliasesFeatureActions.setId, (state, { id }) => ({ ...state, id })),
    on(AliasesFeatureActions.resetId, (state) => ({ ...state, id: undefined }))
  )
});

import { inject, Injectable } from '@angular/core';
import {
  of,
  catchError,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import {
  AuthTokenStorageService,
  ofRoute,
  TokenData,
  ZefAuthApi,
  zefGo,
  zefRefreshTokenFail,
  zefRefreshTokenSuccess,
  zefSetToken
} from '@zerops/zef';
import { userBaseActions } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './authorize.constant';
import { parseAuthToken } from './authorize.utils';

@Injectable()
export class AuthorizeEffect {
  // # Deps
  #actions$ = inject(Actions);
  #authApi = inject(ZefAuthApi);
  #authTokenStorage = inject(AuthTokenStorageService);

  onRouteSetAuth$ = createEffect(() => this.#actions$.pipe(
    ofRoute('authorize/:token'),
    filter(({ payload: { routerState: { params }}}) => !!params?.['token']),
    map(({ payload: { routerState: { params }}}) => parseAuthToken(params['token'])),
    filter((d) => !!d.refreshToken),
    switchMap((d) => this.#authApi.refresh$<TokenData>(d.refreshToken).pipe(
      tap(() => this.#authTokenStorage.removeToken()),
      switchMap((res) => [
        zefSetToken(res, { key: FEATURE_NAME }),
        zefRefreshTokenSuccess(res, undefined),
        userBaseActions.storeUserData(res.userId, d.clientUserId, d.clientServiceId),
        zefGo(['/'])
      ]),
      catchError((err) => of(zefRefreshTokenFail(err, undefined)))
    ))
  ));

}

import {
  Country,
  Currency,
  GeoIpMode,
  Location,
  RemoteLogType
} from '@vshosting/models';

export class SettingsBaseState {
  countries: Country[];
  activeCurrency: Currency;
  geoIpMode: GeoIpMode[];
  remoteLogType: RemoteLogType[];
  locations: Location[];

  constructor() {
    this.countries = [];
    this.geoIpMode = [];
    this.remoteLogType = [];
    this.locations = [];
    this.activeCurrency = { id: 'USD', symbol: '$', displaySymbolBefore: true, roundDigits: 4 };
  }
}

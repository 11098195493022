<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title"
  (closeTrigger)="onClose$.next()">
  <div class="__wrap" *ngIf="!!state.translations">
    <vshcdn-add-domain-form
      #addDomainFormRef
      class="__form"
      (submit)="onAdd$.next()"
      [locations]="state.allowedLocations"
      [state]="state.addDomainFormState"
      [translations]="state.translations">
      <zef-progress-error full [key]="addActionKey">
        <button
          class="c-button--larger  c-button--full"
          (click)="addDomainFormRef.triggerSubmit()"
          mat-flat-button
          color="primary"
          type="button">
          {{ state.translations?.addDomain }}
        </button>
      </zef-progress-error>
    </vshcdn-add-domain-form>
  </div>
</vshc-dialog-wrapper>

import { Component, inject } from '@angular/core';
import { DecimalPipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { filter, map, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import isBefore from 'date-fns/isBefore';

import { ZefReactiveComponentBase } from '@zerops/zef';
import { SatPopoverModule, ZefDialogModule, ZefProgressErrorModule } from '@zerops/zef';
import { FormatBytesPipe, StatusIconComponent } from '@vshosting/components';
import {
  ClientServiceBaseActions,
  CLIENT_SERVICE_STATUS_COLOR_MAP,
  selectClientServiceAggregatedStatistics,
  selectClientServiceBaseData
} from '@vshosting/cdn/core';
import { ADD_DOMAIN_DIALOG_FEATURE_NAME } from '@vshosting/cdn/features';

@Component({
  standalone: true,
  selector: 'vshcdn-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: [ './service-status.component.scss' ],
  imports: [
    DecimalPipe,
    NgFor,
    NgIf,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatCardModule,
    TranslocoLocaleModule,
    FormatBytesPipe,
    SatPopoverModule,
    ZefProgressErrorModule,
    ZefDialogModule,
    StatusIconComponent
  ]
})
export class ServiceStatusFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #translocoService = inject(TranslocoService);

  // # Event Streams
  onTerminalTrial$ = new Subject<void>();

  // # Data
  // -- sync
  terminateTrialActionKey = ClientServiceBaseActions.terminateTrial.type;
  today = new Date();
  addDomainDialogKey = ADD_DOMAIN_DIALOG_FEATURE_NAME;
  clientServiceStatusColorMap = CLIENT_SERVICE_STATUS_COLOR_MAP;

  // -- async
  clientServiceData$ = this.#store.pipe(select(selectClientServiceBaseData));
  onTrial$ = this.clientServiceData$.pipe(
    map((d) => !!(d?.trialEnds && isBefore(new Date(), new Date(d?.trialEnds))))
  );
  tariff$ = this.clientServiceData$.pipe(
    filter((d) => !!d),
    map((d) => ({
      priceListParams: d.pricelistParams,
      priceListParam: d.pricelistParams.pricelistParams.find((itm) => itm.metricId === 'CDN_DATA')
    })
  ));
  clientServiceAggregatedStatistics$ = this.#store.pipe(select(selectClientServiceAggregatedStatistics));
  translations$ = this.#translocoService.selectTranslateObject('serviceStatus');
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');
  activeLang$ = this.#translocoService.langChanges$;

  // # Resolver
  state = this.$connect({
    clientServiceData: this.clientServiceData$,
    onTrial: this.onTrial$,
    tariff: this.tariff$,
    clientServiceAggregatedStatistics: this.clientServiceAggregatedStatistics$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$,
    activeLang: this.activeLang$,
  });

  // # Actions
  #terminalTrialAction$ = this.onTerminalTrial$.pipe(
    map(() => ClientServiceBaseActions.terminateTrial())
  );

  constructor() {
    super();
    this.$dispatchActions([ this.#terminalTrialAction$ ]);
  }

}

<div class="__controls-grid">

  <div class="__controls-inline-grid">

    <div>
      <mat-button-toggle-group class="__type-toggle-group">
        <mat-button-toggle
          [checked]="activeType === item"
          (click)="activeType = item"
          *ngFor="let item of activeTypesArr">
          <mat-icon fontSet="material-icons-outlined" class="__type-toggle-icon">{{ iconMap?.[item] }}</mat-icon>&nbsp;{{ translations?.enums[item] }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div *ngIf="activeType === activeTypes.GRAPH">
      <mat-button-toggle-group class="__type-toggle-group">
        <mat-button-toggle
          [checked]="activeChart === item"
          (click)="activeChart = item"
          *ngFor="let item of chartTypesArr">
          <mat-icon fontSet="material-icons-outlined" class="__type-toggle-icon">{{ iconMap?.[item] }}</mat-icon>&nbsp;<span>{{ translations?.enums[item] }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <!-- labels -->
  <div>
    <ng-container *ngIf="activeType === activeTypes.GRAPH">

      <!-- hits & misses labels -->
      <div class="__labels" *ngIf="activeChart === chartTypes.HIT_MISS">
        <span class="__label" *ngFor="let item of hitMissChartData?.datasets">

          <span class="__label-tag" [style.backgroundColor]="item.backgroundColor"></span>
          <span class="__label-name">{{ translations?.enums[item.label] }}</span>

          <span class="__label-value">
            <mat-icon fontSet="material-icons-outlined" class="__label-value-icon">functions</mat-icon><span class="__label-value-content">{{ sumData[item.label] }}x</span>
          </span>

        </span>
      </div>

      <!-- traffic labels -->
      <div class="__labels" *ngIf="activeChart === chartTypes.TRAFFIC">
        <span class="__label" *ngFor="let item of trafficChartData?.datasets">

          <span class="__label-tag" [style.backgroundColor]="item.backgroundColor"></span>
          <span class="__label-name">{{ translations?.enums[item.label] }}</span>

          <span class="__label-value">
            <mat-icon fontSet="material-icons-outlined" class="__label-value-icon">functions</mat-icon><span class="__label-value-content">{{ sumData[item.label] | formatBytes }}</span>
          </span>

        </span>
      </div>

    </ng-container>
  </div>

  <div class="u-text--right">
    <button
      (click)="popRef.open()"
      [satPopoverAnchor]="popRef"
      mat-stroked-button
      class="__range-toggle-button"
      type="button">
      <mat-icon fontSet="material-icons-outlined" class="__range-toggle-icon">date_range</mat-icon>
      {{ translations?.enums[activeFilter] }}
    </button>
  </div>

</div>

<ng-container *ngIf="activeType === activeTypes.TABLE">
  <table
    mat-table
    class="__table"
    [dataSource]="data">

    <ng-container matColumnDef="date">
      <th
        mat-header-cell
        class="__table-align-left"
        *matHeaderCellDef>
        {{ translations?.table.date }}
      </th>

      <td
        mat-cell
        *matCellDef="let items">
        {{ items.dateFrom | translocoDate: { dateStyle: 'medium' } }} - {{ items.dateTill | translocoDate: { dateStyle: 'medium' } }}
      </td>
    </ng-container>

    <ng-container matColumnDef="traffic">
      <th
        class="__table-align-right"
        mat-header-cell
        *matHeaderCellDef>
        {{ translations?.table.traffic }}
      </th>

      <td
        class="__table-align-right"
        mat-cell
        *matCellDef="let items">
        {{ items.traffic | formatBytes }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hits">
      <th
        class="__table-align-right"
        mat-header-cell
        *matHeaderCellDef>
        {{ translations?.table.hits }}
      </th>

      <td
        class="__table-align-right"
        mat-cell
        *matCellDef="let items">
        {{ items.hits }}
      </td>
    </ng-container>

    <ng-container matColumnDef="miss">
      <th
        class="__table-align-right"
        mat-header-cell
        *matHeaderCellDef>
        {{ translations?.table.miss }}
      </th>

      <td
        class="__table-align-right"
        mat-cell
        *matCellDef="let items">
        {{ items.miss }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ratio">
      <th
        class="__table-align-right"
        mat-header-cell
        *matHeaderCellDef>
        {{ translations?.table.ratio }}
      </th>

      <td
        class="__table-align-right"
        mat-cell
        *matCellDef="let items">
        {{ items.ratio }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="columnsToDisplay">
    </tr>
    <tr
      mat-row
      *matRowDef="let myRowData; columns: columnsToDisplay">
    </tr>

  </table>
</ng-container>

<div class="__canvas-wrap" *ngIf="activeType === activeTypes.GRAPH && !!chartOptions">

  <canvas
    *ngIf="activeChart === chartTypes.HIT_MISS"
    class="__graph-canvas"
    baseChart
    [data]="hitMissChartData"
    [options]="chartOptions[chartTypes.HIT_MISS].options"
    [legend]="false"
    [type]="'bar'">
  </canvas>

  <canvas
    *ngIf="activeChart === chartTypes.TRAFFIC"
    class="__graph-canvas"
    baseChart
    [data]="trafficChartData"
    [options]="chartOptions[chartTypes.TRAFFIC].options"
    [legend]="false"
    [type]="'bar'">
  </canvas>

</div>

<sat-popover [hasBackdrop]="true" #popRef>
  <mat-card class="__date-toggle-card  mat-mdc-elevation-specific  mat-elevation-z24" *ngIf="!!chartOptions">
    <button
      mat-button
      class="__date-toggle-button"
      [class.is-active]="activeFilter === item"
      (click)="filterChange.emit(item)"
      *ngFor="let item of chartOptions?.[activeChart].filters">
      {{ translations?.enums[item] }}
    </button>
  </mat-card>
</sat-popover>
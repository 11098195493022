import { inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { SettingsBaseActions } from './settings-base.state';
import { SettingsBaseApi } from './settings-base.api';

@Injectable()
export class SettingsBaseEffect {

  // # Deps
  #actions$ = inject(Actions);
  #api = inject(SettingsBaseApi)

  onLoadSettings$ = createEffect(() => this.#actions$.pipe(
    ofType(SettingsBaseActions.loadSettings),
    switchMap((action) => this.#api
      .settings$()
      .pipe(
        map((response) => SettingsBaseActions.loadSettingsSuccess(response, action)),
        catchError((err) => of(SettingsBaseActions.loadSettingsFail(err, action)))
      )
    )
  ));

}

<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title + state.domain?.proxyDomain"
  (closeTrigger)="onClose$.next()">
  <div class="__wrap">
    <vshcdn-edit-domain-form
      #editDomainFormRef
      class="__form"
      [apiKey]="state.domain?.imgApiKey"
      [apiSalt]="state.domain?.imgApiSalt"
      [translations]="state.translations"
      [remoteLogTypesTranslations]="state.generalTranslations"
      [locations]="state.allowedLocations"
      [activeLangCode]="state.activeLang"
      [countryList]="state.countryList"
      [remoteLogTypes]="state.remoteLogTypes"
      (submit)="onUpdate$.next()"
      (countryItemRemoved)="onRemoveCountryCode$.next($event)"
      [state]="state.editDomainFormState">
      <zef-progress-error full [key]="updateActionKey">
        <button
          class="c-button--larger  c-button--full"
          (click)="editDomainFormRef.triggerSubmit()"
          mat-flat-button
          color="primary"
          type="button">
          {{ state.translations?.updateDomain }}
        </button>
      </zef-progress-error>
    </vshcdn-edit-domain-form>
  </div>
</vshc-dialog-wrapper>
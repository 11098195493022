<div class="__wrap  c-wrap">
  <div class="__inner">
    <div class="__grid">

      <div>
        <a
          mat-button
          [routerLink]="[ '/' ]"
          class="__logo-button">
          <vshc-logo class="__logo">
          </vshc-logo><span
            [class.is-active]="logoFull"
            class="__logo-suffix">CDN</span>
        </a>
      </div>

      <div class="u-text--center">
        <vshcdn-service-status class="__service-status"></vshcdn-service-status>
      </div>

      <div class="u-text--right">
        <button
          class="__user-button"
          mat-button
          type="button"
          (click)="userPopRef.open()"
          [satPopoverAnchor]="userPopRef">
          <div>

            <vshc-avatar
              class="__avatar"
              [name]="state.clientUser?.fullName"
              [email]="state.clientUser?.email"
              [size]="40"
              [backgroundColor]="'#4c575c'">
            </vshc-avatar>

            <mat-icon class="__avatar-more-icon">more_vert</mat-icon>

          </div>
        </button>
      </div>

    </div>
  </div>
</div>

<!-- user info pop -->
<sat-popover
  #userPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (backdropClicked)="userPopRef.close()"
  [horizontalAlign]="'end'"
  [verticalAlign]="'start'">
  <mat-card class="__user-menu-card  mat-mdc-elevation-specific  mat-elevation-z24">
    <vshc-active-user-info
      [name]="state.clientUser?.fullName"
      [email]="state.clientUser?.email"
      [translations]="state.translations"
      (logout)="onLogout$.next();userPopRef.close()">
    </vshc-active-user-info>
  </mat-card>
</sat-popover>
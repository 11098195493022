import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  // dashboard
  {
    path: '',
    loadChildren: () => import('./features/+dashboard/dashboard.routes')
  },
  // authorize
  {
    path: 'authorize',
    loadChildren: () => import('./features/+authorize/authorize.routes')
  },
  // domain detail
  {
    path: 'domain',
    loadChildren: () => import('./features/+domain-detail/domain-detail.routes')
  }
];

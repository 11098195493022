import { createActionGroup, createFeature, createReducer, on } from '@ngrx/store';

import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef';
import { FEATURE_NAME } from './domain-base.constant';
import { DomainBaseState } from './domain-base.model';
import {
  LoadDomainStatisticsPayload,
  LoadDomainStatisticsResponse,
  PurgeCachePayload,
  PurgeCacheResponse,
  WarmCachePayload,
  WarmCacheResponse
} from './domain-base.model';

export const DomainBaseActions = createActionGroup({
  source: '@vshapps/cdn/core/domain-base',
  events: {
    'warm cache': zefActionPayload<WarmCachePayload>(),
    'warm cache success': zefActionSuccessPayload<WarmCacheResponse>(),
    'warm cache fail': zefActionFailPayload,

    'purge cache': zefActionPayload<PurgeCachePayload>(),
    'purge cache success': zefActionSuccessPayload<PurgeCacheResponse>(),
    'purge cache fail': zefActionFailPayload,

    'load statistics': zefActionPayload<LoadDomainStatisticsPayload>(),
    'load statistics success': zefActionSuccessPayload<LoadDomainStatisticsResponse>(),
    'load statistics fail': zefActionFailPayload,

    'activate': zefActionPayload<string>(),
    'activate success': zefActionSuccessPayload<{ success: boolean; }>(),
    'activate fail': zefActionFailPayload,

    'deactivate': zefActionPayload<string>(),
    'deactivate success': zefActionSuccessPayload<{ success: boolean; }>(),
    'deactivate fail': zefActionFailPayload,

    'alias dns check': zefActionPayload<string>(),
    'alias dns check success': zefActionSuccessPayload<{ success: boolean; }>(),
    'alias dns check fail': zefActionFailPayload
  }
});

const initialState = new DomainBaseState();

const handleLoadStatisticsSuccess = (
  state: DomainBaseState,
  action: ReturnType<typeof DomainBaseActions.loadStatisticsSuccess>
) => {
  const { id, filter } = action.originalAction.data;
  return {
    ...state,
    statistics: {
      ...state.statistics,
      [id]: {
        ...(state.statistics?.[id] || {}),
        [filter]: action.data.data
      }
    }
  };
};

export const domainBaseState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(DomainBaseActions.loadStatisticsSuccess, handleLoadStatisticsSuccess)
  )
});

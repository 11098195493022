import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_NAME } from './user-base.constant';
import { UserBaseState } from './user-base.model';

export const selectUserBaseState = createFeatureSelector<UserBaseState>(FEATURE_NAME);

export const selectUserBaseActiveUserId = createSelector(
  selectUserBaseState,
  (state) => state.activeUserId
);

export const selectUserBaseActiveClientUserId = createSelector(
  selectUserBaseState,
  (state) => state ? state.activeClientUserId : undefined
);

export const selectUserBaseActiveClientServiceId = createSelector(
  selectUserBaseState,
  (state) => state ? state.activeClientServiceId : undefined
);

import { inject, Injectable } from '@angular/core';
import {
  catchError,
  map,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';

import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities,
  successOf,
  ZefSnackService
} from '@zerops/zef';
import { UserEntity } from '@vshosting/cdn/core';
import { DomainEntity } from './domain-base.entity';
import { DomainBaseActions } from './domain-base.state';
import { DomainBaseApi } from './domain-base.api';

@Injectable()
export class DomainBaseEffect {

  // # Deps
  #domainEntity = inject(DomainEntity);
  #userEntity = inject(UserEntity);
  #actions$ = inject(Actions);
  #domainBaseApi = inject(DomainBaseApi);
  #snackService = inject(ZefSnackService);
  #translocoService = inject(TranslocoService);

  // # Data
  // -- async
  translations$ = this.#translocoService.selectTranslateObject('domainBase');

  // # Effects
  // -- entity lists / updates
  setupDomainListStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#domainEntity.listSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' },
      undefined,
      {
        sort: [
          {
            name: 'created',
            ascending: false
          }
        ]
      }
    ))
  ));

  setupDomainListChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this.#domainEntity)
  ));

  setupDomainUpdateStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#domainEntity.updateSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' }
    )
  )));

  onDomainUpdateChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#domainEntity)
  ));

  // -- actions
  onWarmCache$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.warmCache),
    switchMap((action) => this.#domainBaseApi
      .warmCache$(action.data.id, action.data.urls)
      .pipe(
        map((data) => DomainBaseActions.warmCacheSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.warmCacheFail(err, action)))
      )
    )
  ));

  onPurgeCache$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.purgeCache),
    switchMap((action) => this.#domainBaseApi
      .purgeCache$(action.data.id, action.data.urls)
      .pipe(
        map((data) => DomainBaseActions.purgeCacheSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.purgeCacheFail(err, action)))
      )
    )
  ));

  onActivate$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.activate),
    switchMap((action) => this.#domainBaseApi
      .activate$(action.data)
      .pipe(
        map((data) => DomainBaseActions.activateSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.activateFail(err, action)))
      )
    )
  ));

  onDeactivate$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.deactivate),
    switchMap((action) => this.#domainBaseApi
      .deactivate$(action.data)
      .pipe(
        map((data) => DomainBaseActions.deactivateSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.deactivateFail(err, action)))
      )
    )
  ));

  onAliasDnsCheck$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.aliasDnsCheck),
    switchMap((action) => this.#domainBaseApi
      .aliasDnsCheck$(action.data)
      .pipe(
        map((data) => DomainBaseActions.aliasDnsCheckSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.aliasDnsCheckFail(err, action)))
      )
    )
  ));

  onLoadStatistics$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.loadStatistics),
    switchMap((action) => this.#domainBaseApi
      .loadStatistics$(action.data)
      .pipe(
        map((data) => DomainBaseActions.loadStatisticsSuccess(data, action)),
        catchError((err) => of(DomainBaseActions.loadStatisticsFail(err, action)))
      )
    )
  ));

  onAddSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    successOf(this.#domainEntity.addOne),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.domainAdded }))
  ), { dispatch: false });

  onDeletedSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    successOf(this.#domainEntity.deleteOne),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.domainRemoved }))
  ), { dispatch: false });

  onActivateSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.activateSuccess),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.domainActivated }))
  ), { dispatch: false });

  onDeactivateSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.deactivateSuccess),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.domainDeactivated }))
  ), { dispatch: false });

  onAliasDnsCheckSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    ofType(DomainBaseActions.aliasDnsCheckSuccess),
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.aliasDnsCheck }))
  ), { dispatch: false });

}

import { Injectable } from '@angular/core';

import { EntityService } from '@zerops/zef';
import { VshClientUser, VshCoreEntityKeys } from '@vshosting/models';

@Injectable({ providedIn: 'root' })
export class ClientUserEntity extends EntityService<VshClientUser> {
  constructor() {
    super(VshCoreEntityKeys.ClientUser);
  }
}

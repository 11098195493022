import { Pipe, PipeTransform } from '@angular/core'
import { formatBytes } from '../utils'

@Pipe({
  standalone: true,
  name: 'formatBytes',
  pure: true
})
export class FormatBytesPipe implements PipeTransform {
  transform(bytes: number, decimals = 2): string {
    return formatBytes(bytes, decimals)
  }
}


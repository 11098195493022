import { inject, Injectable } from '@angular/core';
import {
  of,
  map,
  mergeMap,
  catchError,
  filter,
  tap
} from 'rxjs';
import {
  Actions,
  ofType,
  createEffect
} from '@ngrx/effects';

import { zefLoginSuccess } from '@zerops/zef';
import { userBaseActions } from './user-base.state';
import { UserEntity } from './user-base.entity';
import { UserBaseApi } from './user-base.api';
import { UserDataStorageService } from './user-base.utils';

@Injectable()
export class UserBaseEffect {

  // # Deps
  #actions$ = inject(Actions);
  #api = inject(UserBaseApi);
  #userEntity = inject(UserEntity);
  #userDataStorage = inject(UserDataStorageService);

  // # Streams
  #onLoadUserSuccess$ = this.#actions$.pipe(ofType(userBaseActions.loadUserSuccess));

  // # Effects
  onLoadUser$ = createEffect(() => this.#actions$.pipe(
    ofType(userBaseActions.loadUser),
    mergeMap((action) => this.#api
      .load$()
      .pipe(
        map((response) => userBaseActions.loadUserSuccess(response, action, action.meta)),
        catchError((err) => of(userBaseActions.loadUserFail(err, action)))
      )
    )
  ));

  onLoadUserSuccessAddToCache$ = createEffect(() => this.#onLoadUserSuccess$.pipe(
    map(({ data }) => this.#userEntity.addToCache([ data ]))
  ));

  onLoadUserSuccessStorage$ = createEffect(() => this.#onLoadUserSuccess$.pipe(
    filter(({ meta }) => meta === zefLoginSuccess.type),
    map(({ data }) => userBaseActions.setUserId(data.id))
  ));

  onStoreUserData$ = createEffect(() => this.#actions$.pipe(
    ofType(userBaseActions.storeUserData),
    tap(({ clientUserId, userId, clientServiceId }) => this.#userDataStorage.setData({
      clientUserId,
      userId,
      clientServiceId
    })),
    map(({ clientUserId, userId, clientServiceId, refresh }) => userBaseActions.storeUserDataSuccess(
      userId,
      clientUserId,
      clientServiceId,
      refresh
    ))
  ));

  onLoadStoredUserData$ = createEffect(() => this.#actions$.pipe(
    ofType(userBaseActions.loadStoredUserData),
    map(() => this.#userDataStorage.getData()),
    filter((d) => !!d),
    map((data) => userBaseActions.loadStoredUserDataSuccess(
      data?.userId,
      data?.clientUserId,
      data?.clientServiceId
    ))
  ));

  onClearUserData$ = createEffect(() => this.#actions$.pipe(
    ofType(userBaseActions.clearUserData),
    tap(() => this.#userDataStorage.removeData())
  ), { dispatch: false });

}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { countryMapReducer } from '@vshosting/components';
import { SettingsBaseState } from './settings-base.model';
import { FEATURE_NAME } from './settings-base.constant';

export const selectSettingsBaseState = createFeatureSelector<SettingsBaseState>(FEATURE_NAME);

export const selectCountries = createSelector(
  selectSettingsBaseState,
  (state) => state.countries
);

export const selectCountryMap = createSelector(
  selectCountries,
  (countries) => countries?.reduce(countryMapReducer, {})
);

export const selectActiveCurrency = createSelector(
  selectSettingsBaseState,
  (state) => state.activeCurrency
);

export const selectRemoteLogTypes = createSelector(
  selectSettingsBaseState,
  (state) => state.remoteLogType
);


import { Injectable } from '@angular/core';
import { validate, box } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { ZefForm } from '@zerops/zef';
import { FEATURE_NAME } from './edit-domain-dialog.constant';
import { EditDomainFormValue } from './components/edit-domain-form';

@Injectable({ providedIn: 'root' })
export class EditDomainDialogForm extends ZefForm<EditDomainFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        forceCache: false,
        backendDomain: null,
        checkBackendCert: false,
        forceCacheTime: null,
        locations: null,
        webp: false,
        enableGeoIp: false,
        geoIpMode: 'BLACKLIST',
        geoIpCountries: box([]),
        enableRemoteLog: false,
        remoteLogDestination: null,
        remoteLogType: 'SYSLOG'
      },
      {
        backendDomain: validate(required),
        locations: validate(required)
      }
    );
  }
}

export interface ItemListColumn {
  name: string;
  translate?: string;
  type: ItemListColumnTypes;
  enableSort: boolean;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  dataPath?: string;
  exportColumns?: string[];
  sort?: string[];
  disableLink?: boolean;
  headerAlign?: string;
}

export enum ItemListColumnTypes {
  custom = 'custom',
  date = 'date',
  boolean = 'boolean',
  default = 'default'
}

import { inject, Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { EntityService } from '@zerops/zef';
import { VshUser, VshCoreEntityKeys } from '@vshosting/models';
import { ClientUserEntity } from '@vshosting/core';
import {
  selectUserBaseActiveClientServiceId,
  selectUserBaseActiveClientUserId,
  selectUserBaseActiveUserId
} from './user-base.selector';

@Injectable({ providedIn: 'root' })
export class UserEntity extends EntityService<VshUser> {

  // # Deps
  #store = inject(Store);
  #clientUserEntity = inject(ClientUserEntity);

  // # Data
  activeClientUser$ = this.#clientUserEntity.entityById$(selectUserBaseActiveClientUserId);
  activeClientServiceId$ = this.#store.pipe(
    select(selectUserBaseActiveClientServiceId),
    distinctUntilChanged(),
    filter((d) => !!d)
  );
  activeUser$ = this.entityById$(selectUserBaseActiveUserId);
  activeClientIdRaw$ = this.#clientUserEntity
    .entityById$(selectUserBaseActiveClientUserId)
    .pipe(
      map((d) => d?.clientId)
    );
  activeClientId$ = this.activeClientIdRaw$
    .pipe(
      distinctUntilChanged(),
      filter((d) => !!d)
    );
  activeUserClientUsers$ = this.activeUser$.pipe(
    map((user) => user ? user.clientUserList : undefined)
  );

  constructor() {
    super(VshCoreEntityKeys.User);
  }

}

<zef-form
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field class="__form-field">
    <mat-form-field color="accent">

      <mat-label>{{ translations?.warmCacheForm.warmCacheLabel }}</mat-label>

      <textarea
        class="__textarea"
        rows="12"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.urls"
        [placeholder]="translations?.warmCacheForm.warmCachePlaceholder"
        matInput></textarea>

      <mat-hint>{{ translations?.warmCacheForm.warmCacheHint }}</mat-hint>

      <mat-error *ngIf="state?.controls.urls.errors['validateUrls']?.actual">
        {{ formErrorTranslations[state?.controls.urls.errors['validateUrls']?.message] }}
      </mat-error>

    </mat-form-field>

  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
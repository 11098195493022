import { SANITIZE_DOMAIN_REGEX } from '@vshosting/components';
import { AllowedLocation } from '@vshosting/models';

export const getDefaultLocation = (allowedLocations: AllowedLocation[]) => {
  const globalLocation = allowedLocations?.find((d) => d.id === 0);

  if (globalLocation) {
    return globalLocation.id;
  }

  if (allowedLocations.length === 1) {
    return allowedLocations[allowedLocations.length - 1].id;
  }

  return undefined;
};

export const sanitizeStandardizeDomain = (value: string) => {
  const sanitizedDomain = value.replace(SANITIZE_DOMAIN_REGEX, '');

  return sanitizedDomain.length >= 7
    ? sanitizedDomain
    : `${sanitizedDomain}cdn`;
};

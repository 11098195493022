import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { box } from 'ngrx-forms';
import flow from 'lodash-es/flow';
import { TranslocoService } from '@ngneat/transloco';

import {
  formValueOnValid,
  selectZefDialogState,
  zefDialogClose,
  ZefReactiveComponentBase
} from '@zerops/zef';
import { ZefProgressErrorModule } from '@zerops/zef';
import { VshcDialogWrapperComponent } from '@vshosting/components';
import {
  selectClientServiceBaseDataAllowedLocations,
  DomainEntity,
  selectCountries,
  selectRemoteLogTypes
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './edit-domain-dialog.constant';
import { EditDomainDialogForm } from './edit-domain-dialog.form';
import {
  cleanupCacheData,
  cleanupGeoIpData,
  cleanupLocations,
  cleanupRemoteLogData
} from './edit-domain-dialog.utils';
import { EditDomainFormComponent } from './components/edit-domain-form';

@Component({
  standalone: true,
  selector: 'vshcdn-edit-domain-dialog',
  templateUrl: './edit-domain-dialog.feature.html',
  styleUrls: [ './edit-domain-dialog.feature.scss' ],
  imports: [
    MatIconModule,
    MatButtonModule,
    ZefProgressErrorModule,
    EditDomainFormComponent,
    VshcDialogWrapperComponent
  ]
})
export class EditDomainDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #domainEntity = inject(DomainEntity);
  #translocoService = inject(TranslocoService);

  // # Forms
  editDomainForm = inject(EditDomainDialogForm);

  // # Event Streams
  onUpdate$ = new Subject<void>();
  onClose$ = new Subject<void>();
  onRemoveCountryCode$ = new Subject<string>();

  // # Data
  // -- sync
  updateActionKey = this.#domainEntity.updateOne.type;

  // -- async
  dialog$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialog$.pipe(map((d) => d?.state));
  activeLang$ = this.#translocoService.langChanges$;
  domainId$ = this.dialog$.pipe(
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );
  domain$ = this.domainId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  allowedLocations$ = this.#store.pipe(
    select(selectClientServiceBaseDataAllowedLocations)
  );
  remoteLogTypes$ = this.#store.pipe(select(selectRemoteLogTypes));
  countryList$ = this.#store.pipe(select(selectCountries));
  translations$ = this.#translocoService.selectTranslateObject('editDomainDialog');
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');

  // # Resolver
  state = this.$connect({
    editDomainFormState: this.editDomainForm.state$,
    allowedLocations: this.allowedLocations$,
    domain: this.domain$,
    open: this.open$,
    countryList: this.countryList$,
    activeLang: this.activeLang$,
    remoteLogTypes: this.remoteLogTypes$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$
  });

  // # Action Streams
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  #updateAction$ = this.onUpdate$.pipe(
    formValueOnValid(this.editDomainForm),
    withLatestFrom(this.domainId$),
    map(([ v, id ]) => this.#domainEntity.updateOne(
      id,
      flow([
        cleanupRemoteLogData,
        cleanupGeoIpData,
        cleanupCacheData,
        cleanupLocations
      ])(v),
      { tag: FEATURE_NAME }
    ))
  );
  #removeCountryCodeAction$ = this.onRemoveCountryCode$.pipe(
    withLatestFrom(this.editDomainForm.value$),
    map(([ code, value ]) => this.editDomainForm.setValue({
      ...value,
      geoIpCountries: box(value.geoIpCountries.value.filter((c) => c !== code))
    }))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this.#closeAction$,
      this.#updateAction$,
      this.#removeCountryCodeAction$
    ]);
  }

}

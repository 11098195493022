import { inject, Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect } from '@ngrx/effects';

import { onWebsocketMessageDispatchAddRemoveEntities, selectZefDialogState } from '@zerops/zef';
import { UserEntity, NodeTaskQueueConsolidatedEntity } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './warm-log-dialog.constant';

@Injectable()
export class WarmLogDialogEffect {

  // # Deps
  #store = inject(Store);
  #actions$ = inject(Actions);
  #userEntity = inject(UserEntity);
  #nodeTaskQueueConsolidatedEntity = inject(NodeTaskQueueConsolidatedEntity);

  // # Streams
  #id$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );

  // # Effects
  // -- entity lists / updates
  setupNodeTaskQueueConsolidatedListStream$ = createEffect(() => this.#id$.pipe(
    switchMap((id) => this.#userEntity.activeClientServiceId$.pipe(
      map((clientServiceId) => this.#nodeTaskQueueConsolidatedEntity.listSubscribe(
        { clientId: clientServiceId, name: 'clientServiceId' },
        { id, name: FEATURE_NAME },
        {
          search: [
            {
              name: 'domainId',
              operator: 'eq',
              value: id
            },
            {
              name: 'action',
              operator: 'eq',
              value: 'WARM'
            }
          ],
          sort: [
            {
              name: 'created',
              ascending: false
            }
          ]
        }
      ))
    ))
  ));

  setupAliasListChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this.#nodeTaskQueueConsolidatedEntity,
      FEATURE_NAME,
      undefined,
      this.#id$
    )
  ));


}

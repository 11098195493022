import { inject, Injectable } from '@angular/core';
import {
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';
import {
  zefDialogClose,
  zefDialogOpen,
  ZefSnackService
} from '@zerops/zef';
import { DomainBaseActions } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './purge-cache-dialog.constant';
import { PurgeCacheDialogForm } from './purge-cache-dialog.form';

@Injectable()
export class PurgeCacheDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #form = inject(PurgeCacheDialogForm);
  #snackService = inject(ZefSnackService);
  #translocoService = inject(TranslocoService);

  // # Data
  // -- async
  translations$ = this.#translocoService.selectTranslateObject('purgeCacheDialog');

  // # Streams
  #purgeCacheSuccess$ = this.#actions$.pipe(
    ofType(DomainBaseActions.purgeCacheSuccess),
    filter((d) => d.originalAction.meta?.tag === FEATURE_NAME)
  );

  #onPurgeCacheDialogOpen$ = this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogOpen.type && key === FEATURE_NAME)
  );

  // # Effects
  onpurgeCacheSuccessCloseDialog$ = createEffect(() => this.#purgeCacheSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onPurgeCacheDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogClose.type && key === FEATURE_NAME),
    delay(150),
    mergeMap(() => ([
      this.#form.reset(),
      this.#form.setDefaultValues()
    ]))
  ));

  onPurgeCacheDialoOpenFocusDomainName$ = createEffect(() => this.#onPurgeCacheDialogOpen$.pipe(
    delay(150),
    map(() => this.#form.focus('urls'))
  ));

  onPurgeCacheSuccessNotification$ = createEffect(() => this.#purgeCacheSuccess$.pipe(
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.purgeCacheSuccess }))
  ), { dispatch: false });

}

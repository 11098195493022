import { AddDomainDialogFeature } from './add-domain-dialog';
import { AliasesFeature } from './aliases';
import { AppBarFeature } from './app-bar';
import { DeleteDomainDialogFeature } from './delete-domain-dialog';
import { DialogErrorFeature } from './dialog-error';
import { DomainExistenceCheckDialogFeature } from './domain-existence-check-dialog';
import { EditDomainDialogFeature } from './edit-domain-dialog';
import { LoginFeature } from './login';
import { PopErrorFeature } from './pop-error';
import { PurgeCacheDialogFeature } from './purge-cache-dialog';
import { PurgeLogDialogFeature } from './purge-log-dialog';
import { SnackErrorFeature } from './snack-error';
import { SnackInfoFeature } from './snack-info';
import { SnackSuccessFeature } from './snack-success';
import { SnackWarningFeature } from './snack-warning';
import { WarmCacheDialogFeature } from './warm-cache-dialog';
import { WarmLogDialogFeature } from './warm-log-dialog';

export const FEATURES_COMPONENTS = [
  AddDomainDialogFeature,
  AliasesFeature,
  AppBarFeature,
  DeleteDomainDialogFeature,
  DialogErrorFeature,
  DomainExistenceCheckDialogFeature,
  EditDomainDialogFeature,
  LoginFeature,
  PopErrorFeature,
  PurgeCacheDialogFeature,
  PurgeLogDialogFeature,
  SnackErrorFeature,
  SnackInfoFeature,
  SnackSuccessFeature,
  SnackWarningFeature,
  WarmCacheDialogFeature,
  WarmLogDialogFeature
] as const;

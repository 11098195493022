import { Injectable } from '@angular/core';
import { validate } from 'ngrx-forms';
import { required, pattern, minLength } from 'ngrx-forms/validation';

import { ZefForm } from '@zerops/zef';
import { DOMAIN_REGEX, SUBDOMAIN_REGEX } from '@vshosting/components';
import { AddDomainFormValue } from './components/add-domain-form';
import { FEATURE_NAME } from './add-domain-dialog.constant';

@Injectable({ providedIn: 'root' })
export class AddDomainDialogForm extends ZefForm<AddDomainFormValue> {
  constructor() {
    super(
      FEATURE_NAME,
      {
        backendDomain: null,
        cdnDomain: null,
        locations: null,
        disabled: false
      },
      {
        backendDomain: validate(required, pattern(DOMAIN_REGEX)),
        cdnDomain: validate(pattern(SUBDOMAIN_REGEX), minLength(7)),
        locations: validate(required)
      }
    );
  }
}

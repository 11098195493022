<div
  class="__circle"
  [ngStyle]="{
    'height.px': size,
    'lineHeight.px': size,
    'width.px': size,
    'borderRadius.px': size,
    'backgroundColor': color
  }">
</div>

@if (text) {
  <div
    [style.fontSize.px]="fontSize"
    class="__status">
    {{ text }}
  </div>
}

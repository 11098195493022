/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input } from "@angular/core";
import { Observable } from "rxjs";
import { CdkCellDef } from "@angular/cdk/table";
import { MatCellDef, MatTableDataSource } from "@angular/material/table";

@Directive({
  standalone: true,
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDef }]
})
export class TypeSafeMatCellDef<T> extends MatCellDef {
  @Input()
  matCellDefDataSource: T[] | Observable<T[]> | MatTableDataSource<T>;

  static ngTemplateContextGuard<T>(
    _: TypeSafeMatCellDef<T>,
    ctx: unknown
  ): ctx is { $implicit: T; index: number } {
      return true;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgFor, NgIf, DecimalPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ChartConfiguration } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { cs, enUS } from 'date-fns/locale';

import { SatPopoverModule } from '@zerops/zef';
import {
  FormatBytesPipe,
  formatBytes,
  formatTooltipTitleDates
} from '@vshosting/components';
import { StatisticsFilters } from '@vshosting/cdn/app';
import { CdnClientServiceStatistic } from '@vshosting/models';

enum ChartTypes {
  COST = 'COST',
  TRAFFIC = 'TRAFFIC'
}

interface ChartConfig {
  options: ChartConfiguration<'bar'>['options'];
  filters: StatisticsFilters[];
}

interface ChartsConfigs {
  [key: string]: ChartConfig;
}

const chartsConfigCreator = (
  translations: any,
  currency: string,
  data: CdnClientServiceStatistic[],
  locale: Locale
): ChartsConfigs  => {
  const filters = Object.values(StatisticsFilters);
  return {
    [ChartTypes.COST]: {
      options: {
        responsive: true,
        // aspectRatio: 16/5,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            itemSort: (a, b) => {
              return b.datasetIndex - a.datasetIndex;
            },
            callbacks: {
              title: (d) => {
                return formatTooltipTitleDates(
                  data[d[0].dataIndex].dateFrom,
                  data[d[0].dataIndex].dateTill,
                  locale
                );
              },
              label: (d) => {
                return `${translations?.enums[d.dataset.label]}: ${d.raw} ${currency}`;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'timeseries',
            ticks: {
              align: 'start'
            },
            adapters: {
              date: {
                locale
              }
            },
            stacked: true,
            grid: {
              display: false
            }
          },
          y: {
            type: 'linear',
            stacked: true,
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              callback: (value) => {
                return `${value} ${currency}`;
              }
            }
          }
        }
      },
      filters: filters.filter((d) => d !== 'X_DAYS_FROM')
    },
    [ChartTypes.TRAFFIC]: {
      options: {
        responsive: true,
        // aspectRatio: 16/5,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            itemSort: (a, b) => {
              return b.datasetIndex - a.datasetIndex;
            },
            callbacks: {
              title: (d) => {
                return formatTooltipTitleDates(
                  data[d[0].dataIndex].dateFrom,
                  data[d[0].dataIndex].dateTill,
                  locale
                );
              },
              label: (d) => {
                return `${translations?.enums[d?.dataset.label]}: ${formatBytes(d?.raw as number)}`;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'timeseries',
            ticks: {
              align: 'start',
            },
            adapters: {
              date: {
                locale
              }
            },
            grid: {
              display: false
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              callback: (value) => {
                return formatBytes(value as number);
              }
            }
          }
        }
      },
      filters: filters.filter((d) => d !== 'X_DAYS_FROM')
    }
  };
};

@Component({
  standalone: true,
  selector: 'vshcdn-client-service-statistics-graph',
  templateUrl: './client-service-statistics-graph.component.html',
  styleUrls: [ './client-service-statistics-graph.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DecimalPipe,
    NgFor,
    NgIf,
    MatIconModule,
    MatCardModule,
    MatButtonToggleModule,
    MatButtonModule,
    FormatBytesPipe,
    NgChartsModule,
    SatPopoverModule
  ]
})
export class ClientServiceStatisticsGraphComponent implements OnChanges {

  @Input()
  data: CdnClientServiceStatistic[];

  @Input()
  activeFilter: StatisticsFilters;

  @Input()
  currency: string;

  @Input()
  translations: any;

  @Input()
  locale: string;

  @Output()
  filterChange = new EventEmitter<StatisticsFilters>();

  activeChart = ChartTypes.COST;
  chartTypes = ChartTypes;
  chartTypesArr = Object.values(ChartTypes);
  chartOptions: ChartsConfigs;
  chartIconMap = {
    [ChartTypes.COST]: 'savings',
    [ChartTypes.TRAFFIC]: 'public'
  };
  costChartData: ChartConfiguration<'bar'>['data'];
  trafficChartData: ChartConfiguration<'bar'>['data'];
  filters = Object.values(StatisticsFilters);
  sumData: Record<string, number>;

  ngOnChanges() {
    if (this.data?.length && !!this.translations && this.currency) {

      const locale = this.locale === 'cs' ? cs : enUS;
      this.chartOptions = chartsConfigCreator(
        this.translations,
        this.currency,
        this.data,
        locale
      );

      this.sumData = this.data.reduce((acc, d) => {
        acc.FIXED_COST = acc.FIXED_COST + parseFloat(d.fixCost.toFixed(0));
        acc.EXTRA_COST = acc.EXTRA_COST + parseFloat(d.extraCost.toFixed(0));
        acc.TRAFFIC = acc.TRAFFIC + d.traffic;
        return acc;
      }, {
        FIXED_COST: 0,
        EXTRA_COST: 0,
        TRAFFIC: 0
      });

      this.costChartData = {
        labels: this.data.map((d) => `${d.dateFrom}`),
        datasets: [
          {
            data: this.data.map((d) => d.fixCost),
            label: 'FIXED_COST',
            backgroundColor: '#3f9c35',
            borderColor: '#3f9c35'
          },
          {
            data: this.data.map((d) => d.extraCost),
            label: 'EXTRA_COST',
            backgroundColor: '#e3ce35',
            borderColor: '#e3ce35'
          }
        ]
      };

      this.trafficChartData = {
        labels: this.data.map((d) => `${d.dateFrom}`),
        datasets: [
          {
            data: this.data.map((d) => d.traffic),
            label: 'TRAFFIC',
            backgroundColor: '#2196f3',
            borderColor: '#2196f3'
          }
        ]
      };

    }
  }

}

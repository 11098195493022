import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
  isDevMode
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  Translation,
  TranslocoLoader,
  translocoConfig,
  provideTransloco
} from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { init, Replay, createErrorHandler } from "@sentry/angular-ivy";
import { apiInterceptor, provideAppApi } from '@vshosting/core';
import { environment } from '../environments/environment';
import { ZefLibModule } from './core/zef-lib';
import { appRoutes } from './app.routes';
import { appReducers, metaReducers } from './app.reducer';
import { AppEffect } from './app.effect';
import { CORE_EFFECTS, CORE_STATES, CORE_PROVIDERS } from './core';
import { FEATURES_EFFECTS, FEATURES_STATES } from './features';

init({
  dsn: environment.sentryDsn,
  environment: environment.sentryEnvName,
  release: environment.version,
  integrations: [
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Replay(),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withInMemoryScrolling({
      scrollPositionRestoration: 'top'
    })),
    provideAppApi(environment.cdnApiUrl),
    provideHttpClient(withInterceptors([apiInterceptor])),
    provideStore(appReducers, { metaReducers }),
    provideEffects([ AppEffect ]),
    ...CORE_STATES,
    ...CORE_EFFECTS,
    ...CORE_PROVIDERS,
    ...FEATURES_STATES,
    ...FEATURES_EFFECTS,
    importProvidersFrom(
      ZefLibModule
    ),
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['en', 'hu', 'cs'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode()
      }),
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        hu: 'hu-HU',
        cs: 'cs-CZ'
      }
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always'
      }
    }
  ]
}

<zef-form
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field class="__form-field">
    <mat-form-field color="accent">

      <mat-label>{{ translations?.addAliasForm.yourAliasLabel }}</mat-label>

      <input
        matInput
        type="text"
        [placeholder]="translations?.addAliasForm.addAliasPlaceholder"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.aliasDomain"
      />

    </mat-form-field>
  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
import { HashMap } from '@zerops/zef';
import { CdnDomainStatistic } from '@vshosting/models';
import { StatisticsFilters } from '@vshosting/cdn/app';

export class DomainBaseState {
  statistics: HashMap<HashMap<CdnDomainStatistic[]>>;
  aliases: HashMap<CdnDomainStatistic[]>;
}

export interface WarmCachePayload {
  id: string;
  urls: string[];
}

export interface PurgeCachePayload {
  id: string;
  urls: string[];
}

type DomainStatisticsFilterTypeUnion = `${StatisticsFilters}`;

export interface LoadDomainStatisticsPayload extends Record<PropertyKey, unknown> {
  id: string;
  filter?: DomainStatisticsFilterTypeUnion;
  days?: number;
  date?: string;
}

export interface WarmCacheResponse {
  processGroupId: string;
}

export interface PurgeCacheResponse {
  processGroupId: string;
}

export interface LoadDomainStatisticsResponse {
  data: CdnDomainStatistic[];
}

export interface DomainAddPayload {
  backendDomain: string;
  cdnDomain: string;
  location: number;
  disabled: boolean;
  clientServiceId: string;
}

export interface DomainUpdatePayload {
  backendDomain: string;
  location: number;
  disabled: boolean;
  forceCache: boolean;
  forceCacheTime: number;
  webp: boolean;
  checkBackendCert: boolean;
  geoIpMode: string;
  geoIpCountries: string[];
  remoteLogType: string;
  remoteLogDestination: string;
}

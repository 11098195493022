import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Pipe,
  PipeTransform
} from '@angular/core';
import { NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ZefFormBase, ZefFormsModule } from '@zerops/zef';
import { AllowedLocation } from '@vshosting/models';
import { environment } from '@vshosting/cdn/env';
import { sanitizeStandardizeDomain } from '../../add-domain-dialog.utils';
import { AddDomainFormState } from './add-domain-form.model';

const defaultExampleDomain = 'example.com';

@Pipe({
  standalone: true,
  name: 'sanitizeDomain'
})
export class SanitizeDomainPipe implements PipeTransform {
  transform(value: string): string {
    return sanitizeStandardizeDomain(value || defaultExampleDomain);
  }
}

@Component({
  standalone: true,
  selector: 'vshcdn-add-domain-form',
  templateUrl: './add-domain-form.component.html',
  styleUrls: [ './add-domain-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    ZefFormsModule,
    SanitizeDomainPipe
  ]
})
export class AddDomainFormComponent extends ZefFormBase<AddDomainFormState> {

  @Input()
  locations: AllowedLocation[];

  @Input()
  translations: any;

  cdnHost = environment.cdnHost;
  defaultExampleDomain = defaultExampleDomain;

}

@if (data$ | async) {
  <ng-container>

    <mat-table
      #table
      [trackBy]="trackBy"
      class="__table"
      [class.has-state]="!!itemStateTemplate"
      matSort
      [matSortActive]="defaultSort"
      [matSortDirection]="defaultSortDirection"
      (matSortChange)="sorted$.next($event); sorted.emit($event)"
      [dataSource]="dataSource">

      @for (col of columns; track col) {
        <ng-container [matColumnDef]="col.name">

          <mat-header-cell
            mat-sort-header
            [arrowPosition]="col.headerAlign === 'right' ? 'before' : 'after'"
            [ngClass]="{
              'is-right': col.headerAlign === 'right',
              'is-center': col.headerAlign === 'center'
            }"
            [disabled]="!col.enableSort || (!col.sort && !col.dataPath)"
            [ngStyle]="{
              'width': col.width || 'auto',
              'min-width': col.minWidth || 'auto',
              'max-width': col.maxWidth || 'auto'
            }"
            *matHeaderCellDef>
            <span [class]="'__header-cell--' + col.name">
              {{
                colsTranslations?.[col.translate] !== undefined
                  ? colsTranslations?.[col.translate]
                  : col.name
              }}
            </span>
          </mat-header-cell>

          <mat-cell
            class="__cell"
            [class.has-link]="!col.disableLink && !!linkPath"
            [ngStyle]="{
              'width': col.width || 'auto',
              'min-width': col.minWidth || 'auto',
              'max-width': col.maxWidth || 'auto'
            }"
            (click)="clickRow(element, $event); navigate(linkPath, element.id, col.disableLink);"
            *matCellDef="let element">

            <ng-container *ngIf="col.type === colTypes.custom && columnTemplateMap[col.name]; else noTemplateRef">
              <ng-template
                [ngTemplateOutletContext]="{ $implicit: element }"
                [ngTemplateOutlet]="columnTemplateMap[col.name]">
              </ng-template>
            </ng-container>

            <ng-template #noTemplateRef>
              <ng-container [ngSwitch]="col.type">

                <ng-container *ngSwitchCase="colTypes.date">
                  <span class="__date">
                    {{ (element | getPropByString: col.dataPath | translocoDate: { dateStyle: 'medium' }) || '-' }}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="colTypes.boolean">
                  {{ (element | getPropByString: col.dataPath) ? 'Yes' : 'No' }}
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ (element | getPropByString: col.dataPath) || '-' }}
                </ng-container>

              </ng-container>
            </ng-template>

          </mat-cell>

        </ng-container>
      }

      <mat-header-row
        class="__header"
        *matHeaderRowDef="colNames">
      </mat-header-row>

      <div
        [class]="'__row-wrap' + (customClassProperty ? ' __custom-row-class_' + row[customClassProperty] : '')"
        [class.has-hover]="!disableHover"
        *matRowDef="let row; columns: colNames">

        <mat-row class="__row"></mat-row>

        <ng-container *ngIf="actionsTemplates && actionsTemplates.length">
          <div
            class="__actions"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="12px"
            [hidden]="!actionsRef.clientWidth"
            #actionsRef>
            @for (template of actionsTemplates; track template) {
              <ng-container>
                <ng-template
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{ $implicit: row }">
                </ng-template>
              </ng-container>
            }

          </div>
        </ng-container>

        <div
          class="__item-state"
          *ngIf="itemStateTemplate">

          <ng-template
            #stateRef
            [ngTemplateOutlet]="itemStateTemplate"
            [ngTemplateOutletContext]="{ $implicit: row }">
          </ng-template>

        </div>

      </div>

    </mat-table>

    <div class="__paginator-wrap">
      <mat-paginator
        *ngIf="paginatorLength > paginatorPageSize"
        class="__paginator"
        hidePageSize
        [pageIndex]="(currentPage$ | async) - 1"
        (page)="page.emit($event)"
        [length]="paginatorLength"
        [pageSize]="paginatorPageSize">
      </mat-paginator>
    </div>

  </ng-container>
}

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import omitBy from 'lodash-es/omitBy';

import {
  LoadDomainStatisticsPayload,
  PurgeCacheResponse,
  WarmCacheResponse
} from './domain-base.model';

@Injectable({ providedIn: 'root' })
export class DomainBaseApi {
  #http = inject(HttpClient);

  deactivate$(domainId: string) {
    return this.#http.put<{ success: boolean; }>(
      `/api/domain/${domainId}/deactivate`,
      {}
    );
  }

  activate$(domainId: string) {
    return this.#http.put<{ success: boolean; }>(
      `/api/domain/${domainId}/activate`,
      {}
    );
  }

  aliasDnsCheck$(domainId: string) {
    return this.#http.put<{ success: boolean; }>(
      `/api/domain/${domainId}/verify-records`,
      {}
    );
  }

  warmCache$(domainId: string, urls: string[]) {
    return this.#http.post<WarmCacheResponse>(
      `/api/domain/${domainId}/warm`,
      {
        params: urls
      }
    );
  }

  purgeCache$(domainId: string, urls: string[]) {
    return this.#http.post<PurgeCacheResponse>(
      `/api/domain/${domainId}/purge`,
      {
        params: urls
      }
    );
  }

  loadStatistics$({ id, filter, date, days }: LoadDomainStatisticsPayload) {
    const params = new HttpParams({
      fromObject: omitBy(
        { filter, date, days },
        (v) => v === undefined
      )
    });

    return this.#http.get<any>(
      `/api/domain/${id}/data?${params.toString()}`
    );
  }

}

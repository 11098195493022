<vshc-dialog-wrapper
  [open]="state.open"
  (closeTrigger)="onClose$.next()"
  [heading]="state.translations?.title"
  [description]="state.aliases?.length !== 0 ? state.translations?.descHasAlias : state.translations?.descNoAlias">

  <div class="__wrap">
    <ng-container *ngIf="state.domainId && state.open">

      <div>
        <vshcdn-aliases
          [forceDeleteShow]="true"
          [id]="state.domainId">
        </vshcdn-aliases>
      </div>

      <div class="__progress-error-wrap">
        <zef-progress-error
          [disabled]="state.aliases?.length !== 0"
          full
          [key]="deleteActionKey">
          <button
            (click)="onDelete$.next()"
            class="__deleteButton  c-button--larger  c-button--full"
            mat-flat-button
            color="warn"
            type="button">
            <mat-icon>delete</mat-icon>
            {{ state.translations?.deleteDomain }}
          </button>
        </zef-progress-error>
      </div>
    </ng-container>
  </div>
</vshc-dialog-wrapper>
<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title"
  (closeTrigger)="onClose$.next()">
  <div class="__wrap">

    <table
      mat-table
      class="__table"
      [dataSource]="state.purgeLog">

      <ng-container matColumnDef="status">

        <th
          mat-header-cell
          *matHeaderCellDef>
          {{ state.translations?.purgeLogTable.status }}
        </th>

        <td
          mat-cell
          *matCellDef="let item">
          <vshc-status-icon
            [color]="$any(taskQueueStatusColorMap)?.[item?.status]"
            [text]="state.generalTranslations?.enums[item.status]"
            [size]="15">
          </vshc-status-icon>
        </td>

      </ng-container>

      <ng-container matColumnDef="taskStarted">

        <th
          mat-header-cell
          *matHeaderCellDef>
          {{ state.translations?.purgeLogTable.taskStarted }}
        </th>

        <td
          mat-cell
          *matCellDef="let item">
          {{ item.taskStarted | translocoDate: { dateStyle: 'long', timeStyle: 'long' } }}
        </td>

      </ng-container>

      <ng-container matColumnDef="taskFinished">

        <th
          mat-header-cell
          *matHeaderCellDef>
          {{ state.translations?.purgeLogTable.taskFinished }}
        </th>

        <td
          mat-cell
          *matCellDef="let item">
          {{ item.taskFinished | translocoDate: { dateStyle: 'long', timeStyle: 'long' } }}
        </td>

      </ng-container>

      <ng-container matColumnDef="userFullName">

        <th
          mat-header-cell
          *matHeaderCellDef>
          {{ state.translations?.purgeLogTable.userFullName }}
        </th>

        <td
          mat-cell
          *matCellDef="let item">
          {{ item.userFullName }}
        </td>

      </ng-container>

      <ng-container matColumnDef="configData">

        <th
          mat-header-cell
          *matHeaderCellDef>
          {{ state.translations?.purgeLogTable.configData }}
        </th>

        <td
          mat-cell
          *matCellDef="let item">
          <div
            *ngFor="let link of item.configData?.links">
            {{ link }}
          </div>
        </td>

      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay">
      </tr>

      <tr
        mat-row
        *matRowDef="let myRowData; columns: columnsToDisplay">
      </tr>

    </table>

  </div>
</vshc-dialog-wrapper>

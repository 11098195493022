import {
  EnvironmentProviders,
  makeEnvironmentProviders
} from '@angular/core';

import { API_CLIENT_ZONE_URL } from './user-base.constant';

export const provideClientZoneApi = (url: string): EnvironmentProviders => {
  return makeEnvironmentProviders([
    {
      provide: API_CLIENT_ZONE_URL,
      useValue: url
    }
  ]);
}

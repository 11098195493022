import { inject, Injectable } from '@angular/core';
import {
  delay,
  filter,
  map,
  mergeMap,
  switchMap
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect } from '@ngrx/effects';
import {
  successOf,
  zefDialogClose,
  zefDialogOpen,
  zefGo
} from '@zerops/zef';
import { CdnDomain } from '@vshosting/models';
import {
  DomainEntity,
  selectClientServiceBaseDataAllowedLocations
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './add-domain-dialog.constant';
import { AddDomainDialogForm } from './add-domain-dialog.form';
import { getDefaultLocation } from './add-domain-dialog.utils';

@Injectable()
export class AddDomainDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #store = inject(Store);
  #domainEntity = inject(DomainEntity);
  #form = inject(AddDomainDialogForm);

  // # Streams
  #onDomainAddDialogSuccess$ = this.#actions$.pipe(
    successOf<CdnDomain>(this.#domainEntity.addOne),
    filter(({ originalAction }) => originalAction.meta?.tag === FEATURE_NAME)
  );

  #onDomainDialogOpen$ = this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogOpen.type && key === FEATURE_NAME)
  );

  // # Effects
  onDomainUpdateSuccessCloseDialog$ = createEffect(() => this.#onDomainAddDialogSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDomainUpdateSuccessGoToDetail$ = createEffect(() => this.#onDomainAddDialogSuccess$.pipe(
    map(({ meta }) => zefGo([ '/domain', meta.rawResult?.id ]))
  ));

  onDomainAddDialogOpenSetData$ = createEffect(() => this.#onDomainDialogOpen$.pipe(
    switchMap(() => this.#store.pipe(
      select(selectClientServiceBaseDataAllowedLocations),
      filter((d) => !!d?.length),
      map((d) => this.#form.setValue({
        ...this.#form.defaultValues,
        location: getDefaultLocation(d)
      }))
    ))
  ));

  onDomainAddDialoOpenFocusDomainName$ = createEffect(() => this.#onDomainDialogOpen$.pipe(
    delay(150),
    map(() => this.#form.focus('backendDomain'))
  ));

  onDomainAddDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogClose.type && key === FEATURE_NAME),
    delay(150),
    mergeMap(() => ([
      this.#form.reset(),
      this.#form.setDefaultValues()
    ]))
  ));

}

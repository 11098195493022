import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { map, distinctUntilChanged, filter } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {
  ZefReactiveComponentBase,
  ZefSnackModule,
  zefSelectAuthState,
  ZefAuthState,
  ZefPermissionService
} from '@zerops/zef';
import { AppState } from './app.model';
import { FEATURES_COMPONENTS } from './features/features.component';
import { Roles } from './app.constant';

@Component({
  standalone: true,
  selector: 'vshcdn-app',
  templateUrl: './app.feature.html',
  styleUrls: [ './app.feature.scss' ],
  imports: [
    RouterOutlet,
    ZefSnackModule,
    ...FEATURES_COMPONENTS
  ]
})
export class AppFeature extends ZefReactiveComponentBase {
  constructor(
    private _store: Store<AppState>,
    private _permissions: ZefPermissionService
  ) {
    super();

    // # Permissions Setup
    // base roles
    this._permissions.define(
      Roles.Authorized,
      () => this._store.pipe(
        select(zefSelectAuthState),
        filter((s) => s !== ZefAuthState.Checking),
        map((state) => state === ZefAuthState.Authorized),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.Unauthorized,
      () => this._store.pipe(
        select(zefSelectAuthState),
        filter((s) => s !== ZefAuthState.Checking),
        map((state) => state === ZefAuthState.Invalid),
        distinctUntilChanged()
      )
    );

  }
}

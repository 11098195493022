import { provideState } from '@ngrx/store';

import { clientServiceBaseState } from './client-service-base';
import { domainBaseState } from './domain-base';
import { settingsBaseState } from './settings-base';
import { userBaseState } from './user-base';

export const CORE_STATES = [
  provideState(clientServiceBaseState),
  provideState(domainBaseState),
  provideState(settingsBaseState),
  provideState(userBaseState)
] as const;

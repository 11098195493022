import { Injectable } from '@angular/core';

import { EntityService } from '@zerops/zef';
import { CdnAlias } from "@vshosting/models";
import { ApiEntityKeys } from '@vshosting/cdn/app';
import { AddAliasPayload } from './alias-base.model';

@Injectable({ providedIn: 'root' })
export class AliasEntity extends EntityService<CdnAlias, AddAliasPayload> {
  constructor() {
    super(ApiEntityKeys.Alias);
  }
}

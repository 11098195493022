<vshc-dialog-wrapper
  [open]="state.open"
  (closeTrigger)="onClose$.next()"
  [heading]="state.translations?.title"
  [description]="state.translations?.desc">
  <div class="__wrap">
    <vshcdn-warm-cache-form
      #warmCacheFormRef
      class="__form"
      (submit)="onWarmCache$.next()"
      [state]="state.warmCacheFormState"
      [translations]="state.translations"
      [formErrorTranslations]="state.formErrorTranslations">
      <zef-progress-error full [key]="warmCacheActionKey">
        <button
          (click)="warmCacheFormRef.triggerSubmit()"
          class="c-button--larger  c-button--full"
          mat-flat-button
          color="primary"
          type="button">
          {{ state.translations?.warmCache }}
        </button>
      </zef-progress-error>
    </vshcdn-warm-cache-form>
  </div>
</vshc-dialog-wrapper>
import { Injectable } from '@angular/core';

import { EntityService } from '@zerops/zef';
import { CdnDataDomain } from "@vshosting/models";
import { ApiEntityKeys } from '@vshosting/cdn/app';

@Injectable({ providedIn: 'root' })
export class DataDomainEntity extends EntityService<CdnDataDomain> {
  constructor() {
    super(ApiEntityKeys.DataDomain);
  }
}

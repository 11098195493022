import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';

import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef';
import { UserEntity } from '@vshosting/cdn/core';
import { DataDomainEntity } from './data-domain-base.entity';

@Injectable()
export class DataDomainBaseEffect {
  // # Deps
  #dataDomainEntity = inject(DataDomainEntity);
  #userEntity = inject(UserEntity);
  #actions$ = inject(Actions);

  // # Effects
  setupDataDomainListStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#dataDomainEntity.listSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' },
      undefined,
      {
        search: [
          {
            name: 'date',
            operator: 'eq',
            value: new Date().setUTCHours(0, 0, 0, 0)
          }
        ]
      }
    ))
  ));

  setupDataDomainListChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this.#dataDomainEntity)
  ));

  setupDataDomainUpdateStream$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((clientServiceId) => this.#dataDomainEntity.updateSubscribe(
      { clientId: clientServiceId, name: 'clientServiceId' }
    )
  )));

  onDataDomainUpdateChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#dataDomainEntity)
  ));

}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VshUser } from '@vshosting/models';
import { API_CLIENT_ZONE_URL } from './user-base.constant';

@Injectable({ providedIn: 'root' })
export class UserBaseApi {
  #apiUrl = inject(API_CLIENT_ZONE_URL);
  #http = inject(HttpClient)

  load$() {
    return this.#http.get<VshUser>(`${this.#apiUrl}/user/info`);
  }

}
